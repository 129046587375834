import paths from "@icarius-localization/paths";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";

export default [
    {
        pages: [paths.schedulesProgramming],
        config: {
            headerName: 'Nombre del turno',
            field: "turnCodeString",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.schedulesProgramming],
        config: {
            headerName: 'Turno',
            field: "turnCode",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.schedulesProgramming],
        config: {
            headerName: 'Fecha de programación',
            field: "scheduleDate",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.schedulesProgramming],
        config: {
            headerName: 'Código horario para la fecha',
            field: "scheduleCode",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.schedulesProgramming],
        config: {
            headerName: 'Nombre del horario',
            field: "scheduleCodeString",
            filter: "agTextColumnFilter",
        }
    },
]