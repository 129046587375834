import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
	numberComparatorMax2,
	numberFormatterMin2,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "CODIGO DE EMPLEADO",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.receipts],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "APELLIDO Y NOMBRE",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("status"),
            field: "ESTADO DOC",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("comments"),
            field: "COMENTARIO DOC",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.receipts],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "ESTADO",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("internalNumber"),
            field: "NRO INTERNO",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("incomeDate"),
            field: "FECHA DE INGRESO",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("contractEndDate"),
            field: "FECHA DE EGRESO",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            hide: true,
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("position"),
            field: "CARGO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("fType"),
            field: "TIPO DE FUNCIONARIO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "LUGAR DE TRABAJO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: 'Centro de costo de gestión',
            field: "CENTRO DE COSTO",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("email"),
            field: "EMAIL",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: 'Email personal',
            field: "EMAIL PERSONAL",
            filter: "agTextColumnFilter",
            hide: true,
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("cellphone"),
            field: "CELULAR",
            filter: "agSetColumnFilter",
            valueGetter: function (params) {
                return (params.data && (params.data["CELULAR"] || getLocalizedString("notAvailable"))) || "";
            },
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("plantPersonnel"),
            field: "PERSONAL DE PLANTA",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("outcomeDate"),
            field: "FECHA DE EGRESO",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            hide: true,
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("function"),
            field: "FUNCION",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("category"),
            field: "CATEGORIA",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("specialty"),
            field: "ESPECIALIDAD",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("civilState"),
            field: "ESTADO CIVIL",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("sex"),
            field: "SEXO",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("nationality"),
            field: "NACIONALIDAD",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },

    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("comuna"),
            field: "COMUNA",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("region"),
            field: "REGION",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "TIPO DE CONTRATO",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("management"),
            field: "GERENCIA",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("department"),
            field: "DEPARTAMENTO",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("salaryUnit"),
            field: "UNIDAD SALARIAL",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("coin"),
            field: "MONEDA",
            filter: "agSetColumnFilter",
            enableRowGroup: true,
            hide: true,
            chartDataType: "series",
            enableValue: true,
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("salary"),
            field: "SUELDO BASICO",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            hide: true,
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("laborUnion"),
            field: "SINDICATO",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.receipts],
        config:
        {
            headerName: getLocalizedString("shift"),
            field: "TURNO",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.receipts],
        config: {
            headerName: getLocalizedString("contratista"),
            field: "CONTRATISTA",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        },
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "CODIGO DE EMPLEADO",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.digitalReceipts],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "APELLIDO Y NOMBRE",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("status"),
            field: "ESTADO DOC",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("comments"),
            field: "COMENTARIO DOC",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalReceipts],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "ESTADO",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("internalNumber"),
            field: "NRO INTERNO",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("incomeDate"),
            field: "FECHA DE INGRESO",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("contractEndDate"),
            field: "FECHA DE EGRESO",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            hide: true,
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("position"),
            field: "CARGO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("fType"),
            field: "TIPO DE FUNCIONARIO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "LUGAR DE TRABAJO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: 'Centro de costo de gestión',
            field: "CENTRO DE COSTO",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("email"),
            field: "EMAIL",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: 'Email personal',
            field: "EMAIL PERSONAL",
            filter: "agTextColumnFilter",
            hide: true,
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("cellphone"),
            field: "CELULAR",
            filter: "agSetColumnFilter",
            valueGetter: function (params) {
                return (params.data && (params.data["CELULAR"] || getLocalizedString("notAvailable"))) || "";
            },
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("plantPersonnel"),
            field: "PERSONAL DE PLANTA",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("outcomeDate"),
            field: "FECHA DE EGRESO",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            hide: true,
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("function"),
            field: "FUNCION",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("category"),
            field: "CATEGORIA",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("specialty"),
            field: "ESPECIALIDAD",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("civilState"),
            field: "ESTADO CIVIL",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("sex"),
            field: "SEXO",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("nationality"),
            field: "NACIONALIDAD",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("comuna"),
            field: "COMUNA",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("region"),
            field: "REGION",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "TIPO DE CONTRATO",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("management"),
            field: "GERENCIA",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("department"),
            field: "DEPARTAMENTO",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("salaryUnit"),
            field: "UNIDAD SALARIAL",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("coin"),
            field: "MONEDA",
            filter: "agSetColumnFilter",
            enableRowGroup: true,
            hide: true,
            chartDataType: "series",
            enableValue: true,
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("salary"),
            field: "SUELDO BASICO",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            hide: true,
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.digitalReceipts],
        config: {
            headerName: getLocalizedString("laborUnion"),
            field: "SINDICATO",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitaldigitalReceipts],
        config:
        {
            headerName: getLocalizedString("shift"),
            field: "TURNO",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.digitaldigitalReceipts],
        config: {
            headerName: getLocalizedString("contratista"),
            field: "CONTRATISTA",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        },
    },
]