import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { useDispatch } from "react-redux";
import { changeCollaboratorsPlanAPIAction } from "../../actions";
import { formatNumberExactDecimals } from "@icarius-utils/format";

const gridStyle = { height: 75, padding: "0px 10px" };

const TransferDialog = (props) => {

  const { open, loading, data, handleClose, planName, planCode, plansList } = props;

  const dispatch = useDispatch();

  const create = (dataToSend) => {
    dispatch(changeCollaboratorsPlanAPIAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const openDateValidationError = () => {
    dispatch(openSnackbarAction({ msg: 'La fecha de publicación debe ser igual o mayor que la fecha actual', severity: "error", duration: 10000 }));
  }

  const { formData, setFormValue, submit } = useHandleForm(data, planCode, create, openValidationError, openDateValidationError);

  if (loading) return null;

  const destinationPlanCodeElement = plansList.find(el => el.code === formData.destinationPlanCode);
  const planCodeElement = plansList.find(el => el.code === planCode);
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={DialogTransition}
        PaperComponent={PaperDraggable}
        maxWidth={"md"}
        fullWidth={true}
      >
        <div className={"dialog-container"}>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            {`Traspasar al colaborador de plan: ${data["Apellido y nombres"]}`}
            <DialogTitleDivider />
          </DialogTitle>
          <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
          <DialogContent style={{ paddingTop: 0 }}>
            <Grid container direction="row" item xs={12}>
              <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* plan */}
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id={`label-plan`}>{"Plan de reconocimiento de destino"}</InputLabel>
                  <Select
                    value={formData.destinationPlanCode}
                    labelId={`label-plan`}
                    id={`select-plan`}
                    onChange={(e) => setFormValue(e.target.value, "destinationPlanCode")}
                    margin={"none"}
                  >
                    {
                      plansList.filter(item => item.status === "A" && item.code !== planCode).map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.code}
                          value={item.code}>
                          {item.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid container direction="row" item xs={12} style={{ marginTop: 20 }}>
                <Grid container item alignItems="center" justify="center" xs={12} sm={6} >
                  <Typography className="whiteText" style={{ fontWeight: 600 }}>
                    {"Actual"}
                  </Typography>
                </Grid>
                <Grid container item alignItems="center" justify="center" xs={12} sm={6} >
                  <Typography className="whiteText" style={{ fontWeight: 600 }}>
                    {"Destino"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="row" item xs={12}>
                <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                  <TextField
                    disabled
                    fullWidth
                    margin={"none"}
                    label={"Nombre del plan"}
                    value={planName}
                    style={{ marginTop: 10 }}
                    inputProps={{ maxLength: 100 }}
                    type={"text"}
                  />
                </Grid>
                <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                  <TextField
                    disabled
                    fullWidth
                    margin={"none"}
                    label={"Nombre del plan"}
                    value={destinationPlanCodeElement?.name || "-"}
                    style={{ marginTop: 10 }}
                    inputProps={{ maxLength: 100 }}
                    type={"text"}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" item xs={12}>
                <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                  <TextField
                    disabled
                    fullWidth
                    margin={"none"}
                    label={"Peso específico"}
                    value={formatNumberExactDecimals(planCodeElement.specificWeight, 2)}
                    style={{ marginTop: 10 }}
                    inputProps={{ maxLength: 100 }}
                    type={"text"}
                  />
                </Grid>
                <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                  <TextField
                    disabled
                    fullWidth
                    margin={"none"}
                    label={"Peso específico"}
                    value={destinationPlanCodeElement ? formatNumberExactDecimals(destinationPlanCodeElement.specificWeight, 2) : "-"}
                    style={{ marginTop: 10 }}
                    inputProps={{ maxLength: 100 }}
                    type={"text"}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" item xs={12}>
                <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                  <TextField
                    disabled
                    fullWidth
                    margin={"none"}
                    label={"Puntos ganados"}
                    value={Number(data["Puntos ganados"]).toFixed(0)}
                    style={{ marginTop: 10 }}
                    inputProps={{ maxLength: 100 }}
                    type={"text"}
                  />
                </Grid>
                <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                  <TextField
                    disabled
                    fullWidth
                    margin={"none"}
                    label={"Puntos ganados"}
                    value={destinationPlanCodeElement ? Number((Number(planCodeElement.specificWeight) * Number(data["Puntos ganados"])) / Number(destinationPlanCodeElement.specificWeight)).toFixed(0) : "-"}
                    style={{ marginTop: 10 }}
                    inputProps={{ maxLength: 100 }}
                    type={"text"}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" item xs={12}>
                <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                  <TextField
                    disabled
                    fullWidth
                    margin={"none"}
                    label={"Puntos disponibles para canje"}
                    value={Number(data["Puntos disponibles para canje"]).toFixed(0)}
                    style={{ marginTop: 10 }}
                    inputProps={{ maxLength: 100 }}
                    type={"text"}
                  />
                </Grid>
                <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                  <TextField
                    disabled
                    fullWidth
                    margin={"none"}
                    label={"Puntos disponibles para canje"}
                    value={destinationPlanCodeElement ? Number((Number(planCodeElement.specificWeight) * Number(data["Puntos disponibles para canje"])) / Number(destinationPlanCodeElement.specificWeight)).toFixed(0) : "-"}
                    style={{ marginTop: 10 }}
                    inputProps={{ maxLength: 100 }}
                    type={"text"}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" item xs={12}>
                <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                  <TextField
                    disabled
                    fullWidth

                    margin={"none"}
                    label={"Puntos canjeados"}
                    value={Number(data["Puntos canjeados"]).toFixed(0)}
                    style={{ marginTop: 10 }}
                    inputProps={{ maxLength: 100 }}
                    type={"text"}
                  />
                </Grid>
                <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                  <TextField
                    disabled
                    fullWidth

                    margin={"none"}
                    label={"Puntos canjeados"}
                    value={destinationPlanCodeElement ? Number((Number(planCodeElement.specificWeight) * Number(data["Puntos canjeados"])) / Number(destinationPlanCodeElement.specificWeight)).toFixed(0) : "-"}
                    style={{ marginTop: 10 }}
                    inputProps={{ maxLength: 100 }}
                    type={"text"}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
            <ButtonDialogAction onClick={submit} isAccept text={"Traspasar"} />
          </DialogActions>
        </div>
      </Dialog>

    </>
  );
}

export default TransferDialog;
