import { useState } from "react";
import * as yup from 'yup';
import { createDateFromDDMMYYYY } from "@icarius-utils/date";

const useHandleForm = (data, planCode, planName, modifyCallback, invalidDataCallback) => {

  const createInitialData = () => {
    const getInitialFieldData = (fieldName) => {
      if (fieldName === 'Nombre del plan') {
        return planName;
      }

      if (fieldName === 'Estado') {
        return data["Estado"] === 'Activo';
      }

      if (fieldName === 'Fecha de asignación al plan') {
        return data['Fecha de asignación al plan'] && createDateFromDDMMYYYY(data['Fecha de asignación al plan']);
      }

      return data[fieldName];
    }

    const fieldNames = [
      'Nombre del plan',
      'Código de empleado',
      'Apellido y nombres',
      'Fecha de asignación al plan',
      'Estado',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      'Nombre del plan': yup.string().required(),
      'Código de empleado': yup.string().required(),
      'Apellido y nombres': yup.string().required(),
      'Fecha de asignación al plan': yup.date().required(),
      'Estado': yup.bool().required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }


  const submit = async () => {
    if (await dataIsValid()) {
      const dataToSend = {
        "collaborator": formData['Código de empleado'],
        "active": formData["Estado"],
        "planCode": planCode,
      };

      modifyCallback(dataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { formData, setFormValue, submit };
}

export default useHandleForm;
