import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { createRelatedTableDefinitionAction, updateRelatedTableDefinitionAction } from "../../actions";
import { useDispatch } from "react-redux";
import Loader from "@icarius-common/loader";

const gridStyle = { height: 75, padding: "0px 10px" };

const CreateEditDialog = (props) => {

  const { open, isLoading, data, handleClose } = props;

  const dispatch = useDispatch();

  const create = (dataToSend) => {
    dispatch(createRelatedTableDefinitionAction(dataToSend))
      .then((res) => {
        if (res?.status === 200) {
          handleClose();
        }
      });
  }

  const modify = (dataToSend) => {
    dispatch(updateRelatedTableDefinitionAction(dataToSend))
      .then((res) => {
        if (res?.status === 200) {
          handleClose();
        }
      });
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const { isCreate, formData, setFormValue, submit } = useHandleForm(data, create, modify, openValidationError);

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {isCreate ? "Crear definición de tabla relacionada" : "Editar definición de tabla relacionada"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Loader open={isLoading} />
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                required
                fullWidth
                margin={"none"}
                label={"Tabla origen"}
                onChange={(e) => setFormValue(e.target.value, "fromTable")}
                value={formData.fromTable}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                required
                fullWidth
                margin={"none"}
                label={"Campo origen"}
                onChange={(e) => setFormValue(e.target.value, "fromField")}
                value={formData.fromField}
                inputProps={{ maxLength: 18 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                required
                fullWidth
                margin={"none"}
                label={"Tabla destino"}
                onChange={(e) => setFormValue(e.target.value, "toTable")}
                value={formData.toTable}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                required
                fullWidth
                margin={"none"}
                label={"Campo código destino"}
                onChange={(e) => setFormValue(e.target.value, "toField")}
                value={formData.toField}
                inputProps={{ maxLength: 18 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                required
                fullWidth
                margin={"none"}
                label={"Campo descripción destino"}
                onChange={(e) => setFormValue(e.target.value, "toFieldDescription")}
                value={formData.toFieldDescription}
                inputProps={{ maxLength: 18 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
          <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default CreateEditDialog;
