import {
  GET_REQUESTS_ROWS,
  GET_REQUESTS_ROWS_FULFILLED,
  GET_REQUESTS_ROWS_REJECTED,
  SEND_MAIL,
  SEND_MAIL_FULFILLED,
  SEND_MAIL_REJECTED,
  DELETE_REQUEST,
  DELETE_REQUEST_FULFILLED,
  DELETE_REQUEST_REJECTED,
} from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getEmployerPendingRequestsAPI, sendRequestMailAPI, deleteRequestAPI } from "@icarius-connection/api";

export const getRequestsTableRows = (isHistorical) => async (dispatch) => {
  dispatch({ type: GET_REQUESTS_ROWS });
  try {
    let response = await getEmployerPendingRequestsAPI(`?historical=${isHistorical}`);
    let requests = response.data && response.data.result;

    dispatch({
      type: GET_REQUESTS_ROWS_FULFILLED,
      payload: {
        requestsRows: requests || [],
        dateFormat: response.data.date_format,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: GET_REQUESTS_ROWS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const sendMail = (data) => async (dispatch) => {
  dispatch({ type: SEND_MAIL });
  try {
    let response = await sendRequestMailAPI({ responsibles: data });

    dispatch({
      type: SEND_MAIL_FULFILLED,
    });
    return response;
  } catch (e) {
    dispatch({ type: SEND_MAIL_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const deleteRequestAction = (data) => async (dispatch) => {
  dispatch({ type: DELETE_REQUEST });
  try {
    let response = await deleteRequestAPI(data);
    let requests = response.data && response.data.result;

    dispatch({
      type: DELETE_REQUEST_FULFILLED,
      payload: {
        requestsRows: requests || [],
        dateFormat: response.data.date_format,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: DELETE_REQUEST_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};