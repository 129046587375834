import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getRecognitionAnalysisAPI,
} from "@icarius-connection/api";


export const getRecognitionAnalysisAction = (onlyActives) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {

    let response = await getRecognitionAnalysisAPI(`?onlyActives=${onlyActives}`);

    let data = response.data && response.data.data;
    let groups = response.data && response.data.groups;
    let dateFormat = response.data && response.data.date_format;

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        data,
        dateFormat,
        groups,
      }
    });
    
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};