import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import UserDialog from "@icarius-pages/certificates/components/userDialog";
import {
  getInitialReceiptData,
  downloadReceiptAction,
  getColaboratorsForReceiptsAction,
  signAndDownloadAction,
  previewDocumentsAction
} from "../actions";
import {
  getPeopleRows,
  getIsLoading,
  getDateFormat,
  getLocale,
  getCalculationProcesses,
  getReceipts,
} from "../selectors";
import { getAppColor, getGeographicalDivisions } from "src/app/selectors";
import { withStyles } from "@material-ui/core/styles";
import { getIsExportingGrid } from "src/app/selectors";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import { MenuItem, Select, FormControl, IconButton, Tooltip } from "@material-ui/core";
import { GetAppIcon, VisibilityIcon } from "@icarius-icons";
import CommonPage from "@icarius-common/commonPage";
import { getProgressIsOpen } from "@icarius-common/circularProgress/selectors";
import paths from "@icarius-localization/paths";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import ConfirmPinDialog from "@icarius-common/confirmPinDialog";
import { RESTORE_DEFAULT_STATE } from "../actionTypes";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import { applyGeographicalDivision } from "@icarius-table/utils";
import { getColumnDefByPage } from "@icarius-table/columnDefs";

const CustomSelect = withStyles({
  select: {
    width: 250,
  },
})(Select);

const useHandleReceipts = (isDigitalSignVersion) => {

  const [selectedReceiptCode, setSelectedReceiptCode] = useState("");
  const [calculationProcessesToUse, setCalculationProcessesToUse] = useState([]); //Se usa solo para la version con firma digital
  const [selectedCalculationProcessCode, setSelectedCalculationProcessCode] = useState("");

  const dispatch = useDispatch();
  const receipts = useSelector(getReceipts);
  const calculationProcesses = useSelector(getCalculationProcesses);

  useEffect(() => {
    /**
      si es version con firma digital Y puede firmar, O no es version con firma digital:
        pegarle al getPeopleRowsAction que le pega a:
          - getAvailableReceiptList para traer los recibos y procesos de calculo
          - getAvailableColaboratorsForReceipt sin params para traer los user genericos
    **/

    const validateHasFiles = (isDigitalSignVersion, res) => {
      const conditionIfSignVersion = res?.data?.receipts?.length > 0;
      const conditionIfRegularVersion = res?.data?.receipts?.length > 0 && res?.data?.calculation_processes?.length > 0;

      if (
        (isDigitalSignVersion && !conditionIfSignVersion)
        ||
        (!isDigitalSignVersion && !conditionIfRegularVersion)
      ) {
        dispatch(openSnackbarAction({ msg: getLocalizedString("noDocumentsToGenerate"), duration: null, severity: "warning" }));
      }
    }

    dispatch(getInitialReceiptData(isDigitalSignVersion)).then(res => validateHasFiles(isDigitalSignVersion, res));
  }, [dispatch, isDigitalSignVersion])

  useEffect(() => {
    /**
      cada vez que cambie el recibo o proceso seleccionado, traer la data nueva
      tambien, si es con firma digital, cuando cambia un recibo, rearma el listado de procesos de calculo
    **/
    if (isDigitalSignVersion) {
      //agarrar el selectedReceiptCode, traer ese recibo, y setear sus calculationProcesses
      const receipt = receipts.find(receipt => receipt.code === selectedReceiptCode);
      if (receipt) {
        setCalculationProcessesToUse(receipt.calculation_processes);
        dispatch(getColaboratorsForReceiptsAction(selectedReceiptCode, selectedCalculationProcessCode, false, receipt.role));
      }
    } else {
      dispatch(getColaboratorsForReceiptsAction(selectedReceiptCode, selectedCalculationProcessCode, true));
    }
  }, [calculationProcesses, receipts, dispatch, isDigitalSignVersion, selectedReceiptCode, selectedCalculationProcessCode])

  return {
    receipts,
    selectedReceiptCode,
    setSelectedReceiptCode,
    selectedCalculationProcessCode,
    setSelectedCalculationProcessCode,
    //si no es version con firma digital, usar los que vienen del selector
    calculationProcessesToUse: isDigitalSignVersion ? calculationProcessesToUse : calculationProcesses,
  };
}

const Receipts = ({ match }) => {

  const isDigitalSignVersion = match.path === paths.digitalReceipts;

  const {
    receipts,
    selectedReceiptCode,
    setSelectedReceiptCode,
    selectedCalculationProcessCode,
    setSelectedCalculationProcessCode,
    calculationProcessesToUse,
  } = useHandleReceipts(isDigitalSignVersion);

  const [isConfirmPinDialogOpen, setIsConfirmPinDialogOpen] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [employeeCode, setEmployeeCode] = useState(null);
  const [documentsToSign, setDocumentsToSign] = useState([]);

  const dispatch = useDispatch();

  const peopleRows = useSelector(getPeopleRows);
  const loadingStatus = useSelector(getIsLoading);
  const exportingGridStatus = useSelector(getIsExportingGrid);
  const color = useSelector(getAppColor);
  const dateFormat = useSelector(getDateFormat);
  const locale = useSelector(getLocale);
  const isProgressLoading = useSelector(getProgressIsOpen);
  const geographicalDivisions = useSelector(getGeographicalDivisions);

  useEffect(() => { //limpiar el store al salir de la pantalla
    return () => dispatch({ type: RESTORE_DEFAULT_STATE });
  }, [dispatch])

  const ownColumnDef = useMemo(() => {
    return applyGeographicalDivision(getColumnDefByPage(match.path), geographicalDivisions);
  }, [match, geographicalDivisions])

  const handleSign = (password, nextSigner) => {
    let dataToSend;

    if (nextSigner) {
      // agrego el firmante al array
      dataToSend = documentsToSign.map(item => {
        return (
          {
            ...item,
            approvants: [...item.approvants, nextSigner],
          }
        )
      })
    } else {
      dataToSend = documentsToSign;
    }

    dispatch(signAndDownloadAction(dataToSend, password))
      .then(() => {
        setDocumentsToSign([]);
        setDialogIsOpen(false);
      });
  }

  const handlePreviewReceipts = (gridRef) => {
    if (selectedReceiptCode === "") {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("selectReceiptType"), severity: "warning" }));
      return;
    }
    if (selectedCalculationProcessCode === "") {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("selectCalcProcess"), severity: "warning" }));
      return;
    }
    if (!gridRef.api.getSelectedRows().length) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    let documents = gridRef.api.getSelectedRows()
      .map(item => {
        return {
          "code": item.dataDoc.code,
          "codRef": item.dataDoc.codRef,
          "dataCode": item.dataDoc.dataCode,
          "role": item.dataDoc.destRole,
        }
      }
      );

    if (documents.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    dispatch(previewDocumentsAction(documents));
  };

  const handleDownloadDocuments = (gridRef) => {
    let selectedCodEmp = [];

    //Con esto obtengo los seleccionados
    gridRef.api
      .getSelectedRows()
      .map(item => selectedCodEmp.push(item["CODIGO DE EMPLEADO"]));

    //Si no hay seleccionado, descargo todos los colaboradores
    if (selectedCodEmp.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
    } else {
      handleGenerate(selectedCodEmp);
    }
  };

  const handleGenerate = (colaborators) => {
    if (selectedReceiptCode === "") {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("selectReceiptType"), severity: "warning" }));
      return;
    }

    if (selectedCalculationProcessCode === "") {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("selectCalcProcess"), severity: "warning" }));
      return;
    }

    if (isDigitalSignVersion) {
      handleSetDataToSign(colaborators);
    } else {
      dispatch(downloadReceiptAction(selectedReceiptCode, selectedCalculationProcessCode, colaborators));
    }
  };

  const handleSetDataToSign = (colaborators) => {
    let selectedRows = [];
    let amountInvalidMail = 0;

    colaborators.forEach(colab => {
      let auxColab = peopleRows.filter(item => item["CODIGO DE EMPLEADO"] === colab)

      if (auxColab.length) {
        if (auxColab[0]["EMAIL"] === "-") {
          amountInvalidMail++;
        } else {
          selectedRows.push(auxColab[0].dataDoc);
        }
      }
    })

    //si hasInvalidMail, tirar snack, sino, el setState
    if (amountInvalidMail > 0) {
      dispatch(openSnackbarAction(
        {
          msg: getLocalizedErrorString("certificateNoMail").replace("{amount}", selectedRows.length).replace("{total}", colaborators.length),
          severity: "info",
          duration: 10000,
        }
      ));
    }
    setDocumentsToSign([...selectedRows]);
    setIsConfirmPinDialogOpen(true);
  }

  const handleRowClick = (employeeRow) => {
    setDialogIsOpen(true);
    setEmployeeCode(employeeRow.data["CODIGO DE EMPLEADO"]);
  };

  const handleCloseDialog = () => {
    setDialogIsOpen(false);
    setEmployeeCode(null);
  };

  const handleSelectReceiptClick = (e, gridRef) => {
    if (e.target.value !== selectedReceiptCode) {
      if (e.target.value === "") {
        setSelectedReceiptCode("");
        setSelectedCalculationProcessCode("");
      } else {
        const receipt = receipts.find(o => o.code === e.target.value);
        if (receipt) {
          setSelectedReceiptCode(e.target.value);
        }
      }
      setFilterForGrid(gridRef);
      gridRef.api.clearRangeSelection();
      gridRef.api.deselectAll();
    }
  };

  const handleSelectCalculationProcess = (e, gridRef) => {
    if (e.target.value !== selectedCalculationProcessCode) {
      if (e.target.value === "") {
        setSelectedCalculationProcessCode("");
      } else {
        const calculationProcess = calculationProcessesToUse.find(o => o.reference === e.target.value);
        if (calculationProcess) {
          setSelectedCalculationProcessCode(e.target.value);
        }
      }
    }
    setFilterForGrid(gridRef);
    gridRef.api.clearRangeSelection();
    gridRef.api.deselectAll();
  };

  const setFilterForGrid = (gridRef) => {
    let filterInstance = gridRef.api.getFilterInstance("PERSONAL DE PLANTA");
    filterInstance.setModel({ type: "set", values: ["Si"] });

    let filterInstanceVigencia = gridRef.api.getFilterInstance("ESTADO");
    filterInstanceVigencia.setModel({ type: "set", values: ["Vigente"] });

    gridRef.api.onFilterChanged();
  };

  const selectReceipts = (gridRef) => ((isDigitalSignVersion) || !isDigitalSignVersion) &&
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginRight: 10 }}>
      <div style={{ maxWidth: 350, display: "flex", marginRight: 10 }}>
        <div style={{ maxWidth: 275 }}>
          <FormControl>
            <CustomSelect
              id="demo-simple-select"
              value={selectedReceiptCode}
              onChange={e => handleSelectReceiptClick(e, gridRef)}
              displayEmpty>
              <MenuItem value="">{getLocalizedString("selectTypeOfDocument")}</MenuItem>
              {
                receipts.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.code}>
                      {item.name}
                    </MenuItem>
                  );
                })
              }
            </CustomSelect>
          </FormControl>
        </div>
      </div>
      {
        selectedReceiptCode &&
        <div style={{ maxWidth: 350, display: "flex", alignItems: "center" }}>
          <div style={{ maxWidth: 275 }}>
            <FormControl>
              <CustomSelect
                id="demo-simple-select"
                value={selectedCalculationProcessCode}
                onChange={e => handleSelectCalculationProcess(e, gridRef)}
                displayEmpty>
                <MenuItem value="">{getLocalizedString("selectCalcMethod")}</MenuItem>
                {
                  (Boolean(calculationProcessesToUse) && calculationProcessesToUse).map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.reference}>
                        {item.name}
                      </MenuItem>
                    );
                  })
                }
              </CustomSelect>
            </FormControl>
          </div>
          <Tooltip title={getLocalizedString(isDigitalSignVersion ? "generateAndDownloadWithSign" : "generateAndDownloadNoSign")}>
            <IconButton
              aria-label="add"
              style={{ padding: 6, paddingBottom: 8 }}
              onClick={() => handleDownloadDocuments(gridRef)}>
              <GetAppIcon htmlColor={color} />
            </IconButton>
          </Tooltip>
          {
            isDigitalSignVersion &&
            <Tooltip title={getLocalizedString("previewDocuments")}>
              <IconButton
                aria-label="add"
                style={{ padding: 6, paddingBottom: 8 }}
                onClick={() => handlePreviewReceipts(gridRef)}>
                <VisibilityIcon htmlColor={color} />
              </IconButton>
            </Tooltip>
          }
        </div>
      }
    </div>

  const helpItems = [
    <MenuItemWithIcon key={"a"} value={"/resources/ICARIUS - Firma Digital de documentos.pdf"} text={getLocalizedString("digitalSignature")} type={"file"} />,
  ]

  return (
    <CommonPage
      helpItems={helpItems}
      customHeader={selectReceipts}
      rowData={peopleRows}
      title={getLocalizedString(isDigitalSignVersion ? "receiptsDigitalFullTitle" : "receiptsFullTitle")}
      gridTitle={getLocalizedString(isDigitalSignVersion ? "receiptsDigitalFullTitle" : "receiptsFullTitle")}
      locale={locale}
      dateFormat={dateFormat}
      ownColumnDef={ownColumnDef}
      handleRowClick={handleRowClick}
      isLoading={loadingStatus || exportingGridStatus}
      hideLoading={isProgressLoading}
      hasExpand
      hasHelp
    >
      <UserDialog
        open={Boolean(dialogIsOpen && employeeCode) || false}
        isLoading={loadingStatus || exportingGridStatus}
        employee={peopleRows.find((employee) => employee["CODIGO DE EMPLEADO"] === employeeCode)}
        hideLoading={isProgressLoading}
        handleGenerate={handleGenerate}
        handleClose={handleCloseDialog}
      />
      {
        isConfirmPinDialogOpen &&
        <ConfirmPinDialog
          open={isConfirmPinDialogOpen}
          text={getLocalizedString("confirmAcceptRequestedDocuments")}
          requiresSelection={receipts.find(receipt => receipt.code === selectedReceiptCode)?.multipleApprovation}
          selectionData={receipts.find(receipt => receipt.code === selectedReceiptCode)?.approvants}
          handleClose={() => setIsConfirmPinDialogOpen(false)}
          handleConfirm={handleSign}
        />
      }
    </CommonPage>
  );
}

export default Receipts;