import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import useRecognitionPlansAssignment from "./useRecognitionPlansAssignment";
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import TransferDialog from "./transferDialog";
import CreateDialog from "./createDialog";
import EditDialog from "./editDialog";
import { useEffect } from "react";
import { MedalTable } from "@icarius-common/medalTable";

const RecognitionPlansAssignment = ({ history, location }) => {

  const planCode = new URLSearchParams(location.search).get("planCode");
  const planName = new URLSearchParams(location.search).get("planName");
  const planStatus = new URLSearchParams(location.search).get("planStatus");

  useEffect(() => {
    if (!planCode) {
      history.push(paths.recognitionPlans);
    }
  }, [planCode, history])

  const {
    data,
    isLoading,
    state,
    handlers,
    colaboratorsList,
    plansList,
  } = useRecognitionPlansAssignment(planCode);

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => history.push(paths.recognitionPlans)}
      type={"goBack"}
    />
  )

  const createButton = () => planStatus === 'Activo' && (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={() => handlers.handleOpenCreateDialog()}
      type={"add"}
    />
  )

  const modifyButton = (gridRef) => (planStatus === 'Activo' || planStatus === 'Detenido') && (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />
  )

  const deleteButton = (gridRef) => (planStatus === 'Activo' || planStatus === 'Detenido') && (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  const changePlanButton = (gridRef) => planStatus === 'Activo' && (
    <CustomIconButton
      title={'Traspasar de plan'}
      onClick={() => handlers.handleOpenTransferDialog(gridRef)}
    >
      <SyncAltIcon />
    </CustomIconButton>
  )

  const medalTableItem = (gridRef) => (
    <CustomIconButton
      title={"Medallero"}
      onClick={() => handlers.handleOpenMedalTableDialog(gridRef)}
      type="medalTable"
    />
  )

  return (
    <CommonPage
      isLoading={isLoading}
      title={`Asignar colaboradores al plan: ${planName} (${planStatus})`}
      gridTitle={`Asignar colaboradores al plan: ${planName} (${planStatus})`}
      columnDefPage={paths.recognitionPlansAssignment}
      rowData={data}
      menuItems={[goBackButton, createButton, modifyButton, deleteButton, changePlanButton, medalTableItem]}
      hasExpand
      hasHelp
    >
      {
        state.transferDialogIsOpen &&
        <TransferDialog
          open={state.transferDialogIsOpen}
          colaborators={colaboratorsList}
          data={state.selectedData}
          loading={isLoading}
          planName={planName}
          plansList={plansList}
          planCode={planCode}
          handleClose={handlers.handleCloseTransferDialog}
        />
      }
      {
        state.createDialogIsOpen &&
        <CreateDialog
          open={state.createDialogIsOpen}
          colaborators={colaboratorsList}
          planCode={planCode}
          loading={isLoading}
          planStatus={planStatus}
          data={data}
          planName={planName}
          handleClose={handlers.handleCloseCreateEditDialog}
        />
      }
      {
        state.editDialogIsOpen &&
        <EditDialog
          open={state.editDialogIsOpen}
          planCode={planCode}
          loading={isLoading}
          data={state.selectedData}
          planName={planName}
          handleClose={handlers.handleCloseCreateEditDialog}
        />
      }
      {
        state.medalTableDialogIsOpen &&
        <MedalTable
          dialog
          planCode={planCode}
          userCode={state.selectedUserCode}
          handleClose={handlers.handleCloseMedalTableDialog}
        />
      }
    </CommonPage>
  )
}

export default RecognitionPlansAssignment;