import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";

export default [
    {
        pages: [paths.requestedDocuments],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "userCode",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.requestedDocuments],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "apeNom",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.requestedDocuments],
        config: {
            headerName: getLocalizedString("email"),
            field: "email",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.requestedDocuments],
        config: {
            headerName: getLocalizedString("typeRequestedDoc"),
            field: "tipo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.requestedDocuments],
        config: {
            headerName: getLocalizedString("codeRequestedDoc"),
            field: "doc",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.requestedDocuments],
        config: {
            headerName: 'Tipo de aprobación',
            field: "approvationString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.requestedDocuments],
        config: {
            headerName: getLocalizedString("requestDate"),
            field: "fecha",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.requestedDocuments],
        config: {
            headerName: getLocalizedString("requestHour"),
            field: "hora",
            filter: "agTextColumnFilter",
            valueGetter: function (params) {
                return (params.data && (params.data["hora"] || "")) || "";
            },
        }
    },
]