import NAME from "./constants";

export const GET_REQUESTS_ROWS = NAME + "/GET_REQUESTS_ROWS";
export const GET_REQUESTS_ROWS_FULFILLED = NAME + "/GET_REQUESTS_ROWS_FULFILLED";
export const GET_REQUESTS_ROWS_REJECTED = NAME + "/GET_REQUESTS_ROWS_REJECTED";
export const SEND_MAIL = NAME + "/SEND_MAIL";
export const SEND_MAIL_FULFILLED = NAME + "/SEND_MAIL_FULFILLED";
export const SEND_MAIL_REJECTED = NAME + "/SEND_MAIL_REJECTED";
export const DELETE_REQUEST = NAME + "/DELETE_REQUEST";
export const DELETE_REQUEST_FULFILLED = NAME + "/DELETE_REQUEST_FULFILLED";
export const DELETE_REQUEST_REJECTED = NAME + "/DELETE_REQUEST_REJECTED";