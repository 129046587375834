import {
    BronzeMedalIcon,
    BronzeShieldIcon,
    SilverMedalIcon,
    SilverShieldIcon,
    GoldShieldIcon,
    GoldMedalIcon,
} from "@icarius-icons/index";

const NAME = "RECOGNITION_PLANS";
export const PRIZE_TYPES = {
    bronzeMedal: {
        icon: BronzeMedalIcon,
        name: 'Medalla de bronce',
        fieldPrefix: 'MedalBronze',
    },
    silverMedal: {
        icon: SilverMedalIcon,
        name: 'Medalla de plata',
        fieldPrefix: 'MedalSilver',
    },
    goldMedal: {
        icon: GoldMedalIcon,
        name: 'Medalla de oro',
        fieldPrefix: 'MedalGold',
    },
    bronzeShield: {
        icon: BronzeShieldIcon,
        name: 'Escudo de bronce',
        fieldPrefix: 'ShieldBronze',
    },
    silverShield: {
        icon: SilverShieldIcon,
        name: 'Escudo de plata',
        fieldPrefix: 'ShieldSilver',
    },
    goldShield: {
        icon: GoldShieldIcon,
        name: 'Escudo de oro',
        fieldPrefix: 'ShieldGold',
    }
}

export default NAME;
