import React from "react";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { CloseIcon } from "@icarius-icons";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import Charts from "./charts";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition.js";

const GraphsDialog = (props) => {

  const {
    open,
    data,
    theme,
    color,
    handleClose,
    handleOpenStatsDialog,
  } = props;

  const dispatch = useDispatch();

  if (!data) {
    handleClose();
    dispatch(openSnackbarAction({ msg: getLocalizedString("noDataToChart"), severity: "error", duration: 10000 }));
    return null;
  }

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"md"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {`Gráficos del plan: ${data.name}`}
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <Charts
          dataToChart={data}
          theme={theme.theme}
          color={color}
          handleOpenStatsDialog={handleOpenStatsDialog}
        />
      </DialogContent>
    </Dialog>
  );
}

export default GraphsDialog;
