import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import FormDialog from "./form/formDialog";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import useSchedulesProgramming from "./useSchedulesProgramming";
import paths from "@icarius-localization/paths";
import { AccountTreeIcon } from "@icarius-icons/";

const SchedulesProgramming = ({ history }) => {

  const {
    data,
    isLoading,
    isExportinGrid,
    createEditDialogIsOpen,
    treeViewIsOpen,
    selectedItem,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseEditDialog,
    handleOpenDeleteDialog,
    handleGrouping,
  } = useSchedulesProgramming();

  const addItem = () =>
    <CustomIconButton
      title={"Crear"}
      onClick={() => handleOpenCreateDialog()}
      type={"add"}
    />

  const deleteItem = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />

  const editItem = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handleOpenEditDialog(gridRef)}
      type={"edit"}
    />

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => history.push(paths.timeDefinition)}
      type={"goBack"}
    />

  const dataTreeButton = (gridRef) => (
    <CustomIconButton
      title={treeViewIsOpen ? "Ver como lista" : "Ver como árbol"}
      onClick={() => handleGrouping(gridRef)}
    >
      <AccountTreeIcon />
    </CustomIconButton>
  )

  return (
    <CommonPage
      title={'Programación de horarios'}
      gridTitle={'Programación de horarios'}
      menuItems={[goBackItem, dataTreeButton, addItem, editItem, deleteItem]}
      columnDefPage={paths.schedulesProgramming}
      rowData={data}
      isLoading={isExportinGrid || isLoading}
      hasExpand
      hasHelp
    >
      {
        createEditDialogIsOpen &&
        <FormDialog
          open={createEditDialogIsOpen}
          data={selectedItem}
          isLoading={isLoading}
          handleClose={handleCloseEditDialog}
        />
      }
    </CommonPage>
  );
}

export default SchedulesProgramming;
