import React from "react";
import Form from "./form";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";

const FormContainer = (props) => {

  const { isLoading, data, handleClose } = props;

  const isEdit = Boolean(data);

  const goBackItem = () =>
  <CustomIconButton
    title={getLocalizedString("goBack")}
    onClick={handleClose}
    type={"goBack"}
  />

  return (
    <CommonPage
      title={`${isEdit ? 'Editar' : 'Crear'} definición de horario`}
      menuItems={[goBackItem]}
      isLoading={isLoading}
      isNotGridPage      
    >
      <Form
        data={data}
        handleClose={handleClose}
      />
    </CommonPage>
  )
}
export default FormContainer;
