import * as actionTypes from "./actionTypes";

const initialState = {
  locale: "es",
  dateFormat: "yyyy/mm/dd",
  requestedDocuments: [],
  approvants: [],
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_REQUESTED_DOCUMENTS_ROWS:
      return { ...state, isLoading: true };
    case actionTypes.GET_REQUESTED_DOCUMENTS_ROWS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        requestedDocuments: action.payload.requestedDocuments,
        dateFormat: action.payload.dateFormat,
        approvants: action.payload.approvants,
      };
    case actionTypes.GET_REQUESTED_DOCUMENTS_ROWS_REJECTED:
      return { ...state, isLoading: false };
    case actionTypes.SIGN_DOCUMENTS:
      return { ...state, isLoading: true };
    case actionTypes.SIGN_DOCUMENTS_FULFILLED: {
      let newDocuments = [...state.requestedDocuments]
      let generatedDocuments = action.payload.documents;

      newDocuments = newDocuments.filter(doc => !generatedDocuments.find(genDoc => (
        doc.dataSign.code === genDoc.code &&
        doc.dataSign.tipoDoc === genDoc.tipoDoc &&
        doc.dataSign.codRef === genDoc.codRef &&
        doc.dataSign.dataCode === genDoc.dataCode &&
        doc.dataSign.destinataries === genDoc.destinataries &&
        doc.dataSign.byRequest === genDoc.byRequest))
      );

      return {
        ...state,
        isLoading: false,
        requestedDocuments: newDocuments,
      };
    }
    case actionTypes.SIGN_DOCUMENTS_REJECTED:
      return { ...state, isLoading: false };
    case actionTypes.DOWNLOAD_MULTIPLE_DOCUMENTS:
      return { ...state, isLoading: true };
    case actionTypes.DOWNLOAD_MULTIPLE_DOCUMENTS_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.DOWNLOAD_MULTIPLE_DOCUMENTS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DELETE_DOCUMENTS:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_DOCUMENTS_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.DELETE_DOCUMENTS_REJECTED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
}
