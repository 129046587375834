import * as actionTypes from "./actionTypes";
import {
  getRecognitionPlansAssignmentAPI,
  changeCollaboratorsPlanApi,
  createRecognitionPlansAssignmentAPI,
  updateRecognitionPlansAssignmentAPI,
  deleteRecognitionPlansAssignmentAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "DUPLICATED_DATA":
      errorString = "Uno de los colaboradores ingresados ya está asociado al plan";
      break;
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getRecognitionPlansAssignmentAction = (planCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getRecognitionPlansAssignmentAPI(`?planCode=${planCode}`);

    let data = response.data && response.data.data;
    let collaboratorsList = response.data && response.data.collaboratorsList;
    let plansList = response.data && response.data.plans;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        data,
        collaboratorsList,
        plansList,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};


export const createRecognitionPlansAssignmentAPIAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createRecognitionPlansAssignmentAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Registro creado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const modifyRecognitionPlansAssignmentAPIAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE });
  try {
    let response = await updateRecognitionPlansAssignmentAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.UPDATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPDATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Registro actualizado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};


export const deleteRecognitionPlanAssignmentAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteRecognitionPlansAssignmentAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Registro eliminado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const changeCollaboratorsPlanAPIAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CHANGE_PLAN });
  try {
    let response = await changeCollaboratorsPlanApi(dataToSend);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CHANGE_PLAN_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CHANGE_PLAN_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Plan creado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CHANGE_PLAN_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};