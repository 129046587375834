import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { deleteSchedulesDefinitionAction, getSchedulesDefinitionAction } from "../actions";
import { getRows, getLoading } from "../selectors";
import { CLEAR_STATE } from "../actionTypes";
import { getIsExportingGrid } from "src/app/selectors";
import { openDialogAction } from "@icarius-common/dialog/actions";


const useABM = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSchedulesDefinitionAction());
    return () => {
      dispatch({ type: CLEAR_STATE });
    }
  }, [dispatch])

  const data = useSelector(getRows);
  const isLoading = useSelector(getLoading);
  const isExportinGrid = useSelector(getIsExportingGrid);

  const [createEditDialogIsOpen, setCreateEditDialogIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleOpenCreateDialog = () => {
    setCreateEditDialogIsOpen(true);
  }

  const handleOpenEditDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un unico item", severity: "warning" }));
      return;
    }

    setCreateEditDialogIsOpen(true);
    setSelectedItem(selectedRows[0]);
  };

  const handleCloseEditDialog = () => {
    setCreateEditDialogIsOpen(false);
    setSelectedItem(null);
  }

  const handleOpenDeleteDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un unico item", severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: "Eliminar horario",
      msg: "¿Desea eliminar el horario?",
      onConfirm: () => handleDelete(selectedRows.map(item => item.code)[0]),
    }));
  }

  const handleDelete = (codeToDelete) => {
    dispatch(deleteSchedulesDefinitionAction({ code: codeToDelete }));
  }

  return {
    data,
    isLoading,
    isExportinGrid,
    createEditDialogIsOpen,
    selectedItem,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseEditDialog,
    handleOpenDeleteDialog,
  }
}

export default useABM;
