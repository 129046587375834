import React, { useEffect, useState } from "react";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteRecognitionPlanAction,
  getRecognitionPlansAction,
} from "../actions";
import {
  getAnnotationList,
  getChangePointsList,
  getData,
  getDiffusionList,
  getLoading,
  getPriceAwardingList,
  getStatusList,
  getCollaboratorsInPlans,
} from "../selectors";
import { PRIZE_TYPES } from "../constants";
import paths from "@icarius-localization/paths";
import { useHistory } from "react-router-dom";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getTheme } from "@icarius-pages/login/selectors";
import { getAppColor } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";

const useRecognitionPlans = () => {

  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [duplicateDialogIsOpen, setDuplicateDialogIsOpen] = useState(false);

  const [detailDialogIsOpen, setDetailDialogIsOpen] = useState(false);
  const [graphsDialogIsOpen, setGraphsDialogIsOpen] = useState(false);
  const [viewPlanDialogIsOpen, setViewPlanDialogIsOpen] = useState(false);
  const [viewStatsDialogIsOpen, setViewStatsDialogIsOpen] = useState(false);
  const [collaboratorsInPlansDialogIsOpen, setCollaboratorsInPlansDialogIsOpen] = useState(false);
  
  const [selectedData, setSelectedData] = useState(null); //seleccionado para editar y detalle
  const [gridTableData, setGridTableData] = useState(null);
  const [statsTableData, setStatsTableData] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);
  const statusList = useSelector(getStatusList);
  const changePointsList = useSelector(getChangePointsList);
  const priceAwardingList = useSelector(getPriceAwardingList);
  const diffusionList = useSelector(getDiffusionList);
  const annotationList = useSelector(getAnnotationList);
  const color = useSelector(getAppColor);
  const theme = useSelector(getTheme);
  const collaboratorsInPlansData = useSelector(getCollaboratorsInPlans);

  useEffect(() => {
    dispatch(getRecognitionPlansAction());
  }, [dispatch])

  const handleOpenCreateDialog = () => {
    setSelectedData(null);
    setCreateDialogIsOpen(true);
  }

  const handleDelete = (document) => {
    if (document?.hasAssignedPoints) {
      dispatch(
        openDialogAction({
          title: "Atención",
          msg: (
            <>
              El plan contiene a empleados con puntos ya asignados. <br />
              Antes de eliminarlo recomendamos que los traspase a otro plan, porque en caso contrario perderan todos sus puntos y reconocimientos obtenidos.
              {Boolean(document.status === "A" || document.status === "D") && 'También puede cambiar el estado del plan a histórico.'}
              <br />
              ¿Confirma su eliminación de todos modos?
            </>
          ),
          onConfirm: () => dispatch(deleteRecognitionPlanAction({ internalCode: document.internalCode })),
          acceptOnly: false
        })
      )
    } else {
      dispatch(deleteRecognitionPlanAction({ internalCode: document.internalCode }));
    }
  }

  const handleOpenAssign = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    history.push({
      pathname: paths.recognitionPlansAssignment,
      search: `?planCode=${documents[0].code}&planName=${documents[0].name}&planStatus=${documents[0].statusString}`,
    });
  }

  const handleOpenDeleteDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: '¿Esta seguro que desea eliminar el plan?',
      onConfirm: () => handleDelete(documents[0]),
    }));
  }

  const handleOpenDuplicateDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setSelectedData(documents[0]);
    setDuplicateDialogIsOpen(true);
  }

  const handleCloseDuplicateDialog = (gridRef) => {
    setDuplicateDialogIsOpen(false);
    setSelectedData(null);
  }

  const handleOpenEditDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setSelectedData(documents[0]);
    setEditDialogIsOpen(true);
  }

  const handleCloseCreateEditDialog = () => {
    setCreateDialogIsOpen(false);
    setEditDialogIsOpen(false);
    setSelectedData(null);
  }

  const handleOpenDetailTable = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setGridTableData(createNewGridTableData(documents[0], true));
    setSelectedData(documents[0]);
    setDetailDialogIsOpen(true);
  }

  const handleCloseDetailTable = () => {
    setDetailDialogIsOpen(false);
    setSelectedData(null);
    setGridTableData(null);
  }

  const handleOpenViewPlanDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setGridTableData(createNewGridTableData(documents[0]));
    setSelectedData(documents[0]);
    setViewPlanDialogIsOpen(true);
  }

  const handleOpenGraphsDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setSelectedData(documents[0]);
    setGraphsDialogIsOpen(true);
  }

  const handleCloseGraphsDialog = () => {
    setGraphsDialogIsOpen(false);
    setSelectedData(null);
  }

  const handleCloseViewPlanDialog = () => {
    setViewPlanDialogIsOpen(false);
    setSelectedData(null);
    setGridTableData(null);
  }


  const handleOpenViewStatsDialog = () => {
    let filteredData = selectedData?.benefitsData || [];
    filteredData = filteredData.map(el => ({ ...el, 'Valor del beneficio': parseFloat(el['Valor del beneficio']) }))
    setStatsTableData(filteredData);
    setViewStatsDialogIsOpen(true);
  }

  const handleCloseViewStatsDialog = () => {
    setViewStatsDialogIsOpen(false);
    setStatsTableData(null);
  }

  const handleCloseCollaboratorsInPlansDialog = () => {
    setCollaboratorsInPlansDialogIsOpen(false);
  }

  const handleOpenCollaboratorsInPlansDialog = () => {
    setCollaboratorsInPlansDialogIsOpen(true);
  }

  const createNewGridTableData = (row, includeType) => {
    return Object.values(PRIZE_TYPES).map(({ name, fieldPrefix }) => {
      const newRow = {
        points: Number(row[`to${fieldPrefix}Points`]),
        value: Number(row[`to${fieldPrefix}Points`] * row.pointValue),
        change: changePointsList.find(item => item.key === row[`changePoints${fieldPrefix}`]).value,
        priceAwarding: priceAwardingList.find(item => item.key === row[`priceAwarding${fieldPrefix}`]).value,
        diffusion: diffusionList.find(item => item.key === row[`diffusion${fieldPrefix}`]).value,
        annotations: annotationList.find(item => item.key === row[`annotationRecord${fieldPrefix}`]).value,
        required: row[`obligationChange${fieldPrefix}`] === 'N' ? "No" : "Si",
        percentage: Number(row[`percentMoneyChange${fieldPrefix}`]),
        maxValue: Number(row[`to${fieldPrefix}Points`] * row.pointValue * row[`percentMoneyChange${fieldPrefix}`] / 100),
      }

      if (includeType) {
        newRow.type = name;
      }

      return newRow;
    });
  }

  const state = {
    editDialogIsOpen,
    createDialogIsOpen,
    duplicateDialogIsOpen,
    detailDialogIsOpen,
    viewPlanDialogIsOpen,
    collaboratorsInPlansDialogIsOpen,
    selectedData,
    gridTableData,
    theme,
    color,
    collaboratorsInPlansData,
    graphsDialogIsOpen,
    viewStatsDialogIsOpen,
    statsTableData,
  }

  const handlers = {
    handleOpenDeleteDialog,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseCreateEditDialog,
    handleOpenDetailTable,
    handleCloseDetailTable,
    handleOpenViewPlanDialog,
    handleCloseViewPlanDialog,
    handleDelete,
    handleOpenAssign,
    handleCloseCollaboratorsInPlansDialog,
    handleOpenCollaboratorsInPlansDialog,
    handleOpenGraphsDialog,
    handleCloseGraphsDialog,
    handleOpenViewStatsDialog,
    handleCloseViewStatsDialog,
    handleOpenDuplicateDialog,
    handleCloseDuplicateDialog,
  }

  return {
    data,
    statusList,
    changePointsList,
    priceAwardingList,
    diffusionList,
    annotationList,
    isLoading,
    state,
    handlers,
  }
}

export default useRecognitionPlans;