import { useState } from "react";
import { createDateFromDDMMYYYY, formatDateYYYYMMDD } from "@icarius-utils/date";
import * as yup from 'yup';

const fieldNames = [
  'turnCode',
  'scheduleDate',
  'scheduleCode',
];

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (field) => {
      if (field === 'scheduleDate') {
        return isCreate ? new Date() : createDateFromDDMMYYYY(data[field])
      }

      return isCreate ? "" : data[field];
    }

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {

    const schema = yup.object().shape({
      'turnCode': yup.string().max(12).required(),
      'scheduleDate': yup.date().required(),
      'scheduleCode': yup.string().max(12).required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      const formDataToSend = { ...formData };
      formDataToSend.scheduleDate = formatDateYYYYMMDD(formData.scheduleDate);

      isCreate ? createCallback(formDataToSend) : modifyCallback({ ...formDataToSend, internalCode: data.internalCode });
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
