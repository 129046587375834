import { getLocalizedString } from "@icarius-localization/strings";

const NAME = "RECEIPTS_PAGE";

export const tableColumns = [
  {
    id: "CODIGO DE EMPLEADO",
    dbColName: "U_idcodigo",
    numeric: false,
    label: getLocalizedString("code"),
  },
  {
    id: "APELLIDO Y NOMBRE",
    dbColName: "U_idapenom",
    numeric: false,
    label: getLocalizedString("nameAndSurname"),
  },
  {
    id: "NRO INTERNO",
    dbColName: "U_idnumint",
    numeric: false,
    label: getLocalizedString("internalNumber"),
  },
  {
    id: "FECHA DE INGRESO",
    dbColName: "U_ctfecing",
    numeric: false,
    date: true,
    label: getLocalizedString("income"),
  },
  {
    id: "MONEDA",
    dbColName: "U_rpmoneda",
    numeric: false,
    label: getLocalizedString("coin"),
  },
  {
    id: "SUELDO BASICO",
    dbColName: "U_rpsalar",
    numeric: false,
    label: getLocalizedString("salary"),
  },
];

export default NAME;
