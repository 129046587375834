import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DialogTransition from "@icarius-common/dialogTransition";

const gridStyle = { height: 75, padding: "0px 10px" };

const DeleteDialog = (props) => {

  const {
    open,
    requestData,
    handleClose,
    handleConfirm,
  } = props;

  const [motive, setMotive] = useState('');

  const dispatch = useDispatch();

  const handleSubmit = () => {

    if (motive.length > 0) {
      handleConfirm({ code: requestData.code, comment: motive });
    } else {
      dispatch(openSnackbarAction({ msg: 'Ingrese el motivo de la eliminación', severity: "warning" }));
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Eliminación de solicitud"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Grid container>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <Typography>
                {`Si confirma, será notificado tanto el solicitante "${requestData.appealer_name}" como el receptor de la solicitud "${requestData.responsible_name}" indicando que la solicitud de "${requestData.type}" con fecha ${requestData.start_date} ha sido eliminada, confirma de todos modos ?`}
              </Typography>
            </Grid>
            <Grid container item alignItems="center" xs={12} style={{ ...gridStyle, marginTop: 10 }} >
              <TextField
                required
                fullWidth
                margin={"none"}
                label={'Motivo de la eliminación'}
                value={motive}
                onChange={(e) => setMotive(e.target.value)}
                inputProps={{ maxLength: 250 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog >
  );
}

export default DeleteDialog;
