import React, { useEffect, useState } from "react";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteRecognitionPlanAssignmentAction,
  getRecognitionPlansAssignmentAction,
} from "../actions";
import {
  getData,
  getLoading,
  getCollaboratorsList,
  getPlansList,
} from "../selectors";
import { RESET_STATE } from "../actionTypes";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getLocalizedString } from "@icarius-localization/strings";

const useRecognitionPlansAssignment = (planCode) => {

  const [transferDialogIsOpen, setTransferDialogIsOpen] = useState(false);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [medalTableDialogIsOpen, setMedalTableDialogIsOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null); //seleccionado para editar y detalle
  const [selectedUserCode, setSelectedUserCode] = useState(null); //para medalTable

  const dispatch = useDispatch();
  const isLoading = useSelector(getLoading);
  const colaboratorsList = useSelector(getCollaboratorsList);
  const plansList = useSelector(getPlansList);
  const data = useSelector(getData);

  useEffect(() => {
    if (Boolean(planCode)) {
      dispatch(getRecognitionPlansAssignmentAction(planCode));
    }
    return () => dispatch({ type: RESET_STATE });
  }, [dispatch, planCode])

  const handleOpenCreateDialog = () => {
    setSelectedData(null);
    setCreateDialogIsOpen(true);
  }

  const handleDelete = (document) => {
    if (parseInt(document["Puntos ganados"]) === 0) {
      dispatch(deleteRecognitionPlanAssignmentAction({ planCode: planCode, collaborator: document["Código de empleado"] }));
    } else {
      dispatch(openDialogAction({
        title: "Confirmación",
        msg: (
          <>
            El colaborador ya tiene puntos ganados, si lo elimina perderá todos sus puntos y reconocimientos obtenidos. <br />
            Antes de eliminarlo puede traspasarlo de plan si lo desea. <br />
            ¿Confirma su eliminación de todos modos?
          </>
        ),
        onConfirm: () => dispatch(deleteRecognitionPlanAssignmentAction({ planCode: planCode, collaborator: document["Código de empleado"] })),
        acceptOnly: false
      }))
    }
  }

  const handleOpenDeleteDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: "¿Está seguro que quiere eliminar al colaborador del plan?",
      onConfirm: () => handleDelete(documents[0]),
    }));
  }

  const handleOpenEditDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setSelectedData(documents[0]);
    setEditDialogIsOpen(true);
  }

  const handleCloseCreateEditDialog = () => {
    setCreateDialogIsOpen(false);
    setEditDialogIsOpen(false);
    setSelectedData(null);
  }

  const handleOpenTransferDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setSelectedData(documents[0]);
    setTransferDialogIsOpen(true);
  }

  const handleCloseTransferDialog = () => {
    setTransferDialogIsOpen(false);
    setSelectedData(null);
  }

  const handleOpenMedalTableDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setSelectedUserCode(documents[0]["Código de empleado"]);
    setMedalTableDialogIsOpen(true);
  }

  const handleCloseMedalTableDialog = (shouldRefetch) => {
    setMedalTableDialogIsOpen(false);
    setSelectedUserCode(null);

    if (shouldRefetch) {
      dispatch(getRecognitionPlansAssignmentAction(planCode));
    }
  }

  const state = {
    editDialogIsOpen,
    createDialogIsOpen,
    selectedData,
    selectedUserCode,
    transferDialogIsOpen,
    medalTableDialogIsOpen,
  }

  const handlers = {
    handleOpenDeleteDialog,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseCreateEditDialog,
    handleOpenTransferDialog,
    handleCloseTransferDialog,
    handleOpenMedalTableDialog,
    handleCloseMedalTableDialog,
  }

  return {
    data,
    isLoading,
    state,
    handlers,
    colaboratorsList,
    plansList,
  }
}

export default useRecognitionPlansAssignment;