import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getCalculationProcessesAPI, rebuildAccumulatorsAPI } from "@icarius-connection/api";
import { getLocalizedErrorString } from "@icarius-localization/strings";

export const getHistoricalProcessesAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getCalculationProcessesAPI("?status=approved");

    let rows = response.data && response.data.result;
    let dateFormat = response.data && response.data.date_format;

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { rows, dateFormat }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const rebuildAccumulatorsAction = (period) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await rebuildAccumulatorsAPI({period});
    
    let status = response.data && response.data.status;
    
    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }
    
    dispatch({
      type: actionTypes.CREATE_FULFILLED,
    });
    
    dispatch(openSnackbarAction({ msg: `La recomposición de acumuladores históricos para ${period} ha finalizado`, severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};