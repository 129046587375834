import {
  GET_PEOPLE_ROWS,
  GET_PEOPLE_ROWS_FULFILLED,
  GET_PEOPLE_ROWS_REJECTED,
  DOWNLOAD_MULTIPLE_RECEIPT,
  DOWNLOAD_MULTIPLE_RECEIPT_FULFILLED,
  DOWNLOAD_MULTIPLE_RECEIPT_REJECTED,
  GET_COLABORATORS_FOR_RECEIPT_REJECTED,
  GET_COLABORATORS_FOR_RECEIPT_FULFILLED,
  GET_COLABORATORS_FOR_RECEIPT,
  SIGN_AND_DOWNLOAD,
  SIGN_AND_DOWNLOAD_FULFILLED,
  SIGN_AND_DOWNLOAD_REJECTED,
} from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { downloadBlob, previewFromURL } from "@icarius-utils/download";
import {
  getAvailableReceiptList,
  getAvailableColaboratorsForReceipt,
  downloadMultipleReceiptApi,
  getCollaboratorsWithDocumentsToSign,
  signAndDownloadApi,
  previewDocumentsAPI
} from "@icarius-connection/api";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import { getFileExtension, getFileName } from "@icarius-utils/fileUpload";
import { RESOURCES_ENDPOINT } from "@icarius-connection/endpoints";

export const getInitialReceiptData = (isSignable) => async (dispatch) => {
  dispatch({ type: GET_PEOPLE_ROWS });
  try {
    //Con esto traigo las opciones
    let responseReceiptList = await getAvailableReceiptList(isSignable ? `?signable=${isSignable}` : "");

    //La query va vacia para traer todo por default
    let response = await getAvailableColaboratorsForReceipt("");
    let colaborators = response.data && response.data.colaborators;
    let date_format = response.data.date_format;
    let help_documentation = response.data.help_documentation;
    let currency_localization = response.data.currency_localization;

    if (colaborators.length <= 0) {
      dispatch({ type: GET_PEOPLE_ROWS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("noEmployeesFound"), severity: "error" }));
      return;
    }

    dispatch({
      type: GET_PEOPLE_ROWS_FULFILLED,
      payload: {
        colaborators: colaborators,
        locale: currency_localization,
        dateFormat: date_format,
        helpDocumentation: help_documentation,
        receipts: responseReceiptList.data.receipts,
        calculation_processes: responseReceiptList.data.calculation_processes,
      },
    });
    return responseReceiptList;
  } catch (e) {
    dispatch({ type: GET_PEOPLE_ROWS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getColaboratorsForReceiptsAction = (receipt, process, isRegularReceipt, rol) => async (dispatch) => {
  dispatch({ type: GET_COLABORATORS_FOR_RECEIPT });

  const shouldNotUseSign = (isRegularReceipt || receipt === "" || process === "");
  const endpointToUse = shouldNotUseSign ? getAvailableColaboratorsForReceipt : getCollaboratorsWithDocumentsToSign;

  try {
    let response = await endpointToUse(shouldNotUseSign ? `?code=${receipt}&reference=${process}` : `?code=${receipt}&reference=${process}&role=${rol}`);
    let colaborators = response.data && (response.data.colaborators || response.data.destinataries);
    let date_format = response.data.date_format;
    let help_documentation = response.data.help_documentation;
    let currency_localization = response.data.currency_localization;
    let status = response.data && response.data.status;

    if(status === "OK"){
      if (colaborators.length <= 0) {
        dispatch({ type: GET_COLABORATORS_FOR_RECEIPT_FULFILLED, payload: { colaborators : []} });
        isRegularReceipt && dispatch(openSnackbarAction({ msg: getLocalizedErrorString("noEmployeesFound"), severity: "error" }));
        return;
      }
  
      dispatch({
        type: GET_COLABORATORS_FOR_RECEIPT_FULFILLED,
        payload: {
          colaborators: colaborators,
          locale: currency_localization,
          dateFormat: date_format,
          helpDocumentation: help_documentation,
        },
      });
    }else{
      dispatch({ type: GET_COLABORATORS_FOR_RECEIPT_REJECTED });      
    }

    return response;
  } catch (e) {
    dispatch({ type: GET_COLABORATORS_FOR_RECEIPT_REJECTED, payload: e });
    if(e.response?.statusText) {
      switch(e.response.statusText){ 
        case "Unauthorized": //usuario no habilitado
          dispatch(openSnackbarAction({ msg: getLocalizedString("receiptUnauth"), severity: "error" }));
          break;
        default:
          dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
      }
    }
    return e.response.status;
  }
};

export const downloadReceiptAction = (code, calculationProcessCode, colaborators, shouldDisableSocket) => async (dispatch) => {
  dispatch({ type: DOWNLOAD_MULTIPLE_RECEIPT });
  try {
    let response = await downloadMultipleReceiptApi({
      code: code,
      reference: calculationProcessCode,
      colaborator_codes: colaborators,
      disable_socket: shouldDisableSocket,
    });

    let documents = response.data;
    let title = response.headers["x-content-namefile"];

    if (title === "TEMPLATE_NOT_FOUND") {
      dispatch({ type: DOWNLOAD_MULTIPLE_RECEIPT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    if (response.status !== 200) {
      dispatch({ type: DOWNLOAD_MULTIPLE_RECEIPT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: DOWNLOAD_MULTIPLE_RECEIPT_FULFILLED,
      payload: {
        documents: documents,
      },
    });

    downloadBlob(response.data, getFileExtension(title), getFileName(title));

    return response;
  } catch (e) {
    dispatch({ type: DOWNLOAD_MULTIPLE_RECEIPT_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const signAndDownloadAction = (docs, password) => async (dispatch) => {
  dispatch({ type: SIGN_AND_DOWNLOAD });
  try {
    dispatch(openSnackbarAction({ msg: getLocalizedString("settingDocuments"), severity: "info", duration: null }));

    let response = await signAndDownloadApi({ docs, password });
    let title = response.headers["x-content-namefile"];    

    if (title === "TEMPLATE_NOT_FOUND") {
      dispatch({ type: SIGN_AND_DOWNLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return response.status;
    }

    if (response.status !== 200) {
      dispatch({ type: SIGN_AND_DOWNLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return response.status;
    }

    dispatch({
      type: SIGN_AND_DOWNLOAD_FULFILLED,
      payload: { docs }     
    });    

    return response.status;
  } catch (e) {
    dispatch({ type: SIGN_AND_DOWNLOAD_REJECTED, payload: e });

    if(e?.response?.data?.status === "VALIDATION_ERROR" && e.response?.data?.rejectedValues[0].error === "TEMPLATE_NOT_FOUND"){
      dispatch(openSnackbarAction({ msg: getLocalizedString("documentInvalidTemplate"), severity: "error" }));
    }else{
      switch(e.response?.statusText){
        case "Unauthorized": //pin incorrecto
          dispatch(openSnackbarAction({ msg: getLocalizedString("receiptWrongPing"), severity: "error" }));
          break;      
        case "Forbidden": //usuario no habilitado
          dispatch(openSnackbarAction({ msg: getLocalizedString("receiptUnauth"), severity: "error" }));
          break;
        case "Unprocessable Entity":
          dispatch(openSnackbarAction({ msg: getLocalizedString("receiptWrongDocument"), severity: "error" }));
          break;
        default:
          dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
      }
    }    
    return e.response.status;
  }
};

export const previewDocumentsAction = (data) => async (dispatch) => {
  dispatch({ type: DOWNLOAD_MULTIPLE_RECEIPT });
  try {
    let response = await previewDocumentsAPI({ docs: data });
    let title = response.headers["x-content-namefile"];

    if (title === "TEMPLATE_NOT_FOUND") {
      dispatch({ type: DOWNLOAD_MULTIPLE_RECEIPT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    if (response.status !== 200) {
      dispatch({ type: DOWNLOAD_MULTIPLE_RECEIPT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({ type: DOWNLOAD_MULTIPLE_RECEIPT_FULFILLED });
    previewFromURL(RESOURCES_ENDPOINT + response.data.path);

    return response;
  } catch (e) {
    if(e?.response?.data?.status === "VALIDATION_ERROR" && e?.response?.data?.error === "TEMPLATE_NOT_FOUND"){
      dispatch(openSnackbarAction({ msg: getLocalizedString("documentInvalidTemplate"), severity: "error" }));
    }else{
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }

    dispatch({ type: DOWNLOAD_MULTIPLE_RECEIPT_REJECTED, payload: e });    
  }
};