import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { IconButton, Grid, Card, Tooltip } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { DeleteIcon } from "@icarius-icons";
import Form from "./form";
import { deleteRetirementDefinitionAction } from "../../actions";
import DataContainer from "@icarius-common/abmComponents/dataContainer";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { formatNumberExactDecimals } from "@icarius-utils/format";

const DataItem = (props) => {

  const { country, data, color, closeNew } = props;
  const dispatch = useDispatch();

  const isCreate = !Boolean(data);

  const [isOpen, setIsOpen] = useState(isCreate); //si viene sin data, que este abierto por defecto

  const handleDeletePublication = () => {
    if (isCreate) {
      closeNew();
    } else {
      dispatch(deleteRetirementDefinitionAction(data));
    }
  }

  const handleClosePublication = () => {
    Boolean(data) && setIsOpen(prev => !prev);
  }

  const formatDecimal = (value) => {
    if (!value) return;

    return formatNumberExactDecimals(value, 2);
  }

  const handleOpenDeleteDialog = () => {
    dispatch(openDialogAction({
      title: 'Eliminar institución de previsión',
      msg: '¿Desea eliminar la institución de previsión?',
      onConfirm: handleDeletePublication,
    }));
  }

  return (
    <>
      <Card style={{ width: "100%", borderRadius: 10, background: (isOpen || isCreate) ? color : "", color: (isOpen || isCreate) && 'white' }}>
        <Grid container item xs={12} direction="row" style={{ padding: "10px 20px" }}>
          <Grid container item xs={11} style={{ cursor: Boolean(data) ? "pointer" : "" }} onClick={handleClosePublication}>
            {
              isCreate ?
                <DataContainer lg={12} sm={12} md={12} text={'Creación de previsión'} />
                :
                <>
                  <DataContainer xs={12} sm={6} md={2} lg={2} text={data.code} />
                  <DataContainer xs={12} sm={6} md={2} lg={2} text={data.name} />
                  <DataContainer xs={12} sm={6} md={2} lg={2} text={`Obligatorio: ${formatDecimal(data.mandatoryPercentage) || "-"}`} />
                  <DataContainer xs={12} sm={6} md={2} lg={2} text={`Seguro: ${formatDecimal(data.disabilityInsurance) || "-"}`} />
                  <DataContainer xs={12} sm={6} md={2} lg={2} text={`Comisión: ${formatDecimal(data.retirementPercentage) || "-"}`} />
                  {country === "CL" && <DataContainer xs={12} sm={6} md={2} lg={2} text={`Previred: ${data.previredCode}`} />}
                </>
            }
          </Grid>
          <Grid container direction="row" item xs={1} justify="center">
            <Tooltip title={getLocalizedString("delete")}>
              <IconButton size={"small"} disableRipple onClick={handleOpenDeleteDialog}>
                <DeleteIcon style={{ fill: "var(--icons)" }} fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Card>
      {
        isOpen &&
        <Form
          country={country}
          data={data}
          handleClose={isCreate ? closeNew : handleClosePublication}
        />
      }
    </>
  );
}

export default DataItem;
