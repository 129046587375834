import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
	numberComparatorMax3,
	numberFormatterMin0Max3,
	numberFilterParamsWithThreeDigits,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.rebuildAccumulators],
        config: {
            headerName: getLocalizedString("processCode"),
            field: "Código de proceso",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.rebuildAccumulators],
        config: {
            headerName: getLocalizedString("processDescription"),
            field: "Descripción",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.rebuildAccumulators],
        config: {
            headerName: getLocalizedString("processType"),
            field: "Tipo de proceso",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.rebuildAccumulators],
        config: {
            headerName: getLocalizedString("processSubtype"),
            field: "Tipo de subproceso",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.rebuildAccumulators],
        config: {
            headerName: getLocalizedString("processDateFrom"),
            field: "Fecha desde",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.rebuildAccumulators],
        config: {
            headerName: getLocalizedString("processDateTo"),
            field: "Fecha hasta",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.rebuildAccumulators],
        config: {
            headerName: getLocalizedString("processPeriod"),
            field: "Período",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.rebuildAccumulators],
        config: {
            headerName: getLocalizedString("processPaymentDate"),
            field: "Fecha de pago",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.rebuildAccumulators],
        config: {
            headerName: getLocalizedString("processStatus"),
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.rebuildAccumulators],
        config: {
            headerName: getLocalizedString("processDateLast"),
            field: "Fecha último proceso",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.rebuildAccumulators],
        config: {
            headerName: getLocalizedString("processTimeLast"),
            field: "Hora último proceso",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.rebuildAccumulators],
        config: {
            headerName: "Colaboradores procesados",
            field: "Colaboradores procesados",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax3,
            filterParams: numberFilterParamsWithThreeDigits,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max3,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.rebuildAccumulators],
        config: {
            headerName: "Proceso publicado",
            field: "Proceso publicado",
            filter: "agSetColumnFilter",
        }
    },
]