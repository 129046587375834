import * as actionTypes from "./actionTypes";

const initialState = {
  data: [],
  groups: [],
  dateFormat: "yyyy/mm/dd",
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.RESET_STATE:
      return initialState;

    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        groups: action.payload.groups,
        dateFormat: action.payload.dateFormat,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}
