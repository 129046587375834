import React from "react";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import RatingRenderer from "@icarius-table/renderersAndFilters/ratingRenderer";
import AvatarRenderer from "@icarius-table/renderersAndFilters/avatarRenderer";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { useHistory } from "react-router-dom";
import { getLocalizedString } from "@icarius-localization/strings";
import useRecognitionAnalysis from "./useRecognitionAnalysis";
import useTemplates from "@icarius-common/templates/components/useTemplates";
import CreateTemplateDialog from "@icarius-common/templates/components/createTemplateDialog";
import TemplatesHeader from "@icarius-common/templates/components/templatesHeader";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

const RecognitionAnalysis = () => {

  const history = useHistory();
  const {
    data,
    dateFormat,
    isLoading,
    onlyActives,
    groups,
    groupSelectedKey,
    handleSwitchOnlyActives,
    handleSelectGroup,
  } = useRecognitionAnalysis();

  const {
    user,
    templates,
    selectedTemplate,
    isLoadingTemplates,
    createTemplateDialogIsOpen,
    getRole,
    handleCreateTemplate,
    handleImportTemplates,
    handleExportTemplates,
    handleSelectTemplateAndUpdateGrid,
    handleOpenCreateTemplateDialog,
    handleOpenUpdateTemplateDialog,
    handleOpenDeleteTemplateDialog,
    handleCloseCreateTemplateDialog,
  } = useTemplates('RECOGNITION_ANALYSIS');

  const customHeader = (gridRef) => (
    <>
      <FormControl style={{ width: '200px', marginRight: 10 }}>
        <InputLabel shrink id={`label-group`}>{'Grupo'}</InputLabel>
        <Select
          value={groupSelectedKey}
          onChange={(e) => handleSelectGroup(e.target.value)}
          labelId={`label-group`}
          id={`select-group`}
          margin={"none"}
          displayEmpty
        >
          <MenuItem className={"whiteText"} value={''}>
            {'Todos'}
          </MenuItem>
          {
            groups.map((item) => (
              <MenuItem
                className={"whiteText"}
                key={item.id}
                value={item.id}
              >
                {item.name}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <TemplatesHeader
        gridRef={gridRef}
        user={user}
        templates={templates}
        selectedTemplate={selectedTemplate}
        getRole={getRole}
        handleImportTemplates={handleImportTemplates}
        handleExportTemplates={handleExportTemplates}
        handleOpenCreateTemplateDialog={handleOpenCreateTemplateDialog}
        handleOpenDeleteTemplateDialog={handleOpenDeleteTemplateDialog}
        handleOpenUpdateTemplateDialog={handleOpenUpdateTemplateDialog}
        handleSelectTemplateAndUpdateGrid={handleSelectTemplateAndUpdateGrid}
      />
    </>
  )

  const child = (gridRef) => (
    <>
      {
        createTemplateDialogIsOpen &&
        <CreateTemplateDialog
          open={createTemplateDialogIsOpen}
          level={user.level}
          handleCreateTemplate={(role, name) => handleCreateTemplate(gridRef, role, name)}
          handleCloseDialog={handleCloseCreateTemplateDialog}
        />
      }
    </>
  )

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => history.push(paths.recognitionScreenSelector)}
      type={"goBack"}
    />
  )

  return (
    <CommonPage
      title={'Análisis de reconocimientos'}
      gridTitle={'Análisis de reconocimientos'}
      menuItems={[goBackButton]}
      customHeader={customHeader}
      columnDefPage={paths.recognitionAnalysis}
      rowData={data}
      dateFormat={dateFormat}
      isLoading={isLoading || isLoadingTemplates}
      hasSelectAll
      hasExpand
      hasHelp
      hasSwitch
      handleSwitchClick={handleSwitchOnlyActives}
      switchValue={onlyActives}
      switchLabelOn={'Planes activos'}
      switchLabelOff={'Todos los planes'}
      frameworkComponents={{ AvatarRenderer, RatingRenderer }}
    >
      {child}
    </CommonPage>
  );
}

export default RecognitionAnalysis;
