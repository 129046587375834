import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
	numberComparatorMax2,
	numberFormatterMin2,
	numberFormatterMin0Max3,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.recognitionPlansAssignment],
        config: {
            headerName: 'Código de empleado',
            field: 'Código de empleado',
            filter: "agTextColumnFilter",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.recognitionPlansAssignment],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.recognitionPlansAssignment],
        config:
        {
            headerName: "Estado",
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.recognitionPlansAssignment],
        config: {
            headerName: "Fecha de asignación al plan",
            field: "Fecha de asignación al plan",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.recognitionPlansAssignment],
        config: {
            headerName: 'Puntos ganados',
            field: 'Puntos ganados',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },

    {
        pages: [paths.recognitionPlansAssignment],
        config: {
            headerName: 'Puntos disponibles para canje',
            field: 'Puntos disponibles para canje',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.recognitionPlansAssignment],
        config: {
            headerName: 'Puntos canjeados',
            field: 'Puntos canjeados',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.recognitionPlansAssignment],
        config: {
            headerName: 'Cantidad de medallas de bronce',
            field: 'Cantidad de medallas de bronce',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max3,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.recognitionPlansAssignment],
        config: {
            headerName: 'Cantidad de medallas de plata',
            field: 'Cantidad de medallas de plata',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max3,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.recognitionPlansAssignment],
        config: {
            headerName: 'Cantidad de medallas de oro',
            field: 'Cantidad de medallas de oro',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max3,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.recognitionPlansAssignment],
        config: {
            headerName: 'Cantidad de escudos de bronce',
            field: 'Cantidad de escudos de bronce',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max3,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.recognitionPlansAssignment],
        config: {
            headerName: 'Cantidad de escudos de plata',
            field: 'Cantidad de escudos de plata',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max3,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.recognitionPlansAssignment],
        config: {
            headerName: 'Cantidad de escudos de oro',
            field: 'Cantidad de escudos de oro',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max3,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.recognitionPlansAssignment],
        config: {
            headerName: 'Nivel de reconocimientos',
            field: 'Nivel de reconocimientos',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.recognitionPlansAssignment],
        config: {
            headerName: "Fecha de actualización",
            field: "Fecha de actualización",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.recognitionPlansAssignment],
        config: {
            headerName: 'Hora de actualización',
            field: 'Hora de actualización',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.recognitionPlansAssignment],
        config:
        {
            headerName: "Tipo de funcionario",
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.recognitionPlansAssignment],
        config:
        {
            headerName: "Cargo",
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.recognitionPlansAssignment],
        config:
        {
            headerName: "Gerencia",
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.recognitionPlansAssignment],
        config:
        {
            headerName: "Centro de costo de gestión",
            field: "Centro de costo de gestión",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.recognitionPlansAssignment],
        config:
        {
            headerName: "Tipo de contrato",
            field: "Tipo de contrato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.recognitionPlansAssignment],
        config:
        {
            headerName: "Fecha de contratación",
            field: "Fecha de contratación",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.recognitionPlansAssignment],
        config:
        {
            headerName: "Lugar de trabajo",
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.recognitionPlansAssignment],
        config:
        {
            headerName: "Obra o Faena",
            field: "Obra o Faena",
            filter: "agSetColumnFilter",
        }
    },
]