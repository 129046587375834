import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import { useSelector } from "react-redux";
import useRelatedTablesDefinition from "./useRelatedTablesDefinition";
import CreateEditDialog from "./dialogs/createEditDialog";
import { getIsAdmin } from "src/app/selectors";

const RelatedTablesDefinition = ({ history }) => {

  const {
    data,
    typeList,
    statusList,
    isLoading,
    state,
    handlers
  } = useRelatedTablesDefinition();

  const isAdmin = useSelector(getIsAdmin);

  const createButton = () => isAdmin && (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={() => handlers.handleOpenCreateDialog()}
      type={"add"}
    />
  )

  const modifyButton = (gridRef) => isAdmin && (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />
  )

  const deleteButton = (gridRef) => isAdmin && (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => history.push(paths.extensibilityScreenSelector)}
      type={"goBack"}
    />

  return (
    <CommonPage
      isLoading={isLoading}
      title={'Definición de tablas relacionadas'}
      gridTitle={'Definición de tablas relacionadas'}
      columnDefPage={paths.relatedTablesDefinition}
      rowData={data}
      menuItems={[goBackItem, createButton, modifyButton, deleteButton]}
      hasExpand
      hasHelp
      ignoreProcessesbyPath
    >
      {
        (state.createDialogIsOpen || state.editDialogIsOpen) &&
        <CreateEditDialog
          open={(state.createDialogIsOpen || state.editDialogIsOpen)}
          isLoading={isLoading}
          data={state.selectedDocument}
          handleClose={handlers.handleCloseCreateEditDialog}
          typeList={typeList}
          statusList={statusList}
        />
      }
    </CommonPage>
  )
}

export default RelatedTablesDefinition;