import {
  GET_REQUESTS_ROWS,
  GET_REQUESTS_ROWS_FULFILLED,
  GET_REQUESTS_ROWS_REJECTED,
  SEND_MAIL,
  SEND_MAIL_REJECTED,
  SEND_MAIL_FULFILLED,
  DELETE_REQUEST,
  DELETE_REQUEST_FULFILLED,
  DELETE_REQUEST_REJECTED,
} from "./actionTypes";

const initialState = {
  requestsRows: [],
  locale: "es",
  dateFormat: "yyyy/mm/dd",
  gettingRequestsRows: false,
  helpDocumentation: "",
  exporting: false,
  sendingMail: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_REQUESTS_ROWS:
      return { ...state, gettingRequestsRows: true };
    case GET_REQUESTS_ROWS_FULFILLED:
      return {
        ...state,
        gettingRequestsRows: false,
        requestsRows: action.payload.requestsRows,
        dateFormat: action.payload.dateFormat,
        helpDocumentation: action.payload.helpDocumentation,
      };
    case GET_REQUESTS_ROWS_REJECTED:
      return { ...state, gettingRequestsRows: false };
    case SEND_MAIL:
      return { ...state, sendingMail: true };
    case SEND_MAIL_FULFILLED:
      return { ...state, sendingMail: false };
    case SEND_MAIL_REJECTED:
      return { ...state, sendingMail: false };

    case DELETE_REQUEST:
      return { ...state, gettingRequestsRows: true };
    case DELETE_REQUEST_FULFILLED:
      return {
        ...state,
        gettingRequestsRows: false,
        requestsRows: action.payload.requestsRows,
        dateFormat: action.payload.dateFormat,
        helpDocumentation: action.payload.helpDocumentation,
      };
    case DELETE_REQUEST_REJECTED:
      return { ...state, gettingRequestsRows: false };

    default:
      return state;
  }
}
