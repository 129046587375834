import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (data, planCode, createCallback, invalidDataCallback, invalidPublicationDateCallback) => {

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === 'destinationPlanCode') {
        return '';
      }

      if (fieldName === 'collaborator') {
        return data["Código de empleado"];
      }

      if (fieldName === 'planCode') {
        return planCode;
      }
    }

    const fieldNames = [
      'planCode',
      'destinationPlanCode',
      'collaborator',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      'planCode': yup.string().max(20).required(),
      'destinationPlanCode': yup.string().max(20).required(),
      'collaborator': yup.string().max(20).required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      const dataToSend = {
        "planCode": formData['planCode'],
        "destinationPlanCode": formData['destinationPlanCode'],
        "collaborator": formData['collaborator'],
      };

      createCallback(dataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { formData, setFormValue, submit };
}

export default useHandleForm;
