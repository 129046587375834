import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { PRIZE_TYPES } from "@icarius-pages/recognitionPlans/constants";

const RowItem = ({ children, type }) => {
  return (
    <Grid container item alignItems="center" sm={12} md={6} style={{ padding: 10 }}>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <div
          className="myDesktopShadow"
          style={{
            background: 'var(--secondaryBackgroundColor)',
            height: 135,
            width: 135,
            borderRadius: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          {type.icon()}
          <Typography className="whiteText" align='center' style={{ width: '100%', padding: "0px 5px", paddingTop: 10, fontSize: 14 }}>
            {type.name}
          </Typography>
        </div>
        <div style={{ width: 'calc(100% - 135px)', paddingLeft: 5 }}>
          {children}
        </div>
      </div>
    </Grid>
  )
}

const MedalAndShieldContainer = (props) => {

  const {
    slot1,
    slot2,
    slot3,
    slot4,
    slot5,
    slot6,
  } = props;

  return (
    <>
      <RowItem type={PRIZE_TYPES.bronzeMedal}>
        {slot1}
      </RowItem>
      <RowItem type={PRIZE_TYPES.bronzeShield}>
        {slot4}
      </RowItem>
      <RowItem type={PRIZE_TYPES.silverMedal}>
        {slot2}
      </RowItem>
      <RowItem type={PRIZE_TYPES.silverShield}>
        {slot5}
      </RowItem>
      <RowItem type={PRIZE_TYPES.goldMedal}>
        {slot3}
      </RowItem>
      <RowItem type={PRIZE_TYPES.goldShield}>
        {slot6}
      </RowItem>
    </>
  );
}



export default MedalAndShieldContainer;
