import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import Form from "./form";

const CreateEditForm = (props) => {

  const {
    isLoading,
    data,
    statusList,
    changePointsList,
    priceAwardingList,
    diffusionList,
    annotationList,
    handleClose,
  } = props;

  const isEdit = Boolean(data);

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => handleClose(false)}
      type={"goBack"}
    />

  return (
    <CommonPage
      title={`${isEdit ? 'Editar' : 'Crear'} plan de reconocimiento`}
      menuItems={[goBackItem]}
      isLoading={isLoading}
      isNotGridPage
    >
      <Form
        data={data}
        statusList={statusList}
        changePointsList={changePointsList}
        priceAwardingList={priceAwardingList}
        diffusionList={diffusionList}
        annotationList={annotationList}
        handleClose={handleClose}
      />
    </CommonPage>
  )
}
export default CreateEditForm;
