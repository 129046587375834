import * as actionTypes from "./actionTypes";

const initialState = {
  data: [],
  statusList: [],
  changePointsList: [],
  priceAwardingList: [],
  diffusionList: [],
  annotationList: [],
  collaboratorsInPlans: [],
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        statusList: action.payload.statusList,
        changePointsList: action.payload.changePointsList,
        priceAwardingList: action.payload.priceAwardingList,
        diffusionList: action.payload.diffusionList,
        annotationList: action.payload.annotationList,
        collaboratorsInPlans: action.payload.collaboratorsInPlans,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.CREATE:
      return { ...state, isLoading: true };
    case actionTypes.CREATE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case actionTypes.CREATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.MODIFY:
      return { ...state, isLoading: true };
    case actionTypes.MODIFY_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case actionTypes.MODIFY_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DELETE:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case actionTypes.DELETE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DUPLICATE:
      return { ...state, isLoading: true };
    case actionTypes.DUPLICATE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case actionTypes.DUPLICATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DOWNLOAD:
      return { ...state, isLoading: true };
    case actionTypes.DOWNLOAD_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.DOWNLOAD_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.UPLOAD:
      return { ...state, isLoading: true };
    case actionTypes.UPLOAD_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.UPLOAD_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
