import NAME from "./constants";

export const GET = NAME + "/GET";
export const GET_FULFILLED = NAME + "/GET_FULFILLED";
export const GET_REJECTED = NAME + "/GET_REJECTED";

export const CREATE = NAME + "/CREATE";
export const CREATE_FULFILLED = NAME + "/CREATE_FULFILLED";
export const CREATE_REJECTED = NAME + "/CREATE_REJECTED";

export const CLEAR_STATE = NAME + "/CLEAR_STATE";