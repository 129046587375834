import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { CloseIcon } from "@icarius-icons";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  BronzeMedalIcon,
  BronzeShieldIcon,
  GoldMedalIcon,
  GoldShieldIcon,
  SilverMedalIcon,
  SilverShieldIcon,
} from "@icarius-icons/index";

const StyledDialog = withStyles({
  paper: {
    minWidth: "90%",
  },
})(Dialog);

const items = [
  'Puntos para obtenerlos',
  'Valor de cada reconocimiento',
  'Canje de puntos',
  'Entrega de premio',
  'Difusión',
  'Registro de incidentes críticos (Anotaciones)',
  'Obligatoriedad de canje',
  '% máximo para canje por dinero',
  'Valor máximo para canje por dinero',
];

const ViewPlanDialog = (props) => {

  const {
    open,
    name,
    data,
    handleClose,
  } = props;

  const getTableRows = () => {
    const rows = [];
    const fields = Object.keys(data[0]);

    for (let i = 0; i < 9; i++) {
      const cells = [];
      for (let j = 0; j < 6; j++) {
        const value = data[j][fields[i]];

        cells.push(
          <TableCell key={`${i}${j}`} align="center">{typeof value === 'number' ? value.toLocaleString('da') : value}</TableCell>
        )
      }

      rows.push(
        <TableRow key={i}>
          <TableCell key={`item${i}`} align="center">{items[i]}</TableCell>
          {cells}
        </TableRow>
      )
    }

    return rows;
  }

  return (
    <StyledDialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"md"}>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogTitle style={{ cursor: 'move', paddingBottom: 0 }} id="draggable-dialog-title">
        {`Visualización del plan: ${name}`}
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper} style={{ maxHeight: '75vh' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center">Items</TableCell>
                <TableCell align="center"><BronzeMedalIcon /></TableCell>
                <TableCell align="center"><SilverMedalIcon /></TableCell>
                <TableCell align="center"><GoldMedalIcon /></TableCell>
                <TableCell align="center"><BronzeShieldIcon /></TableCell>
                <TableCell align="center"><SilverShieldIcon /></TableCell>
                <TableCell align="center"><GoldShieldIcon /></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getTableRows()}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </StyledDialog>
  );
}

export default ViewPlanDialog;