import { useState, useEffect } from "react";
import { formatDateHHMM } from "@icarius-utils/date";
import * as yup from 'yup';

const fieldNames = [
  { name: 'code', isTimeField: false },
  { name: 'name', isTimeField: false },
  { name: 'group', isTimeField: false },
  { name: 'isProgrammable', isTimeField: false },
  { name: 'breakProg', isTimeField: false },
  { name: 'abrev', isTimeField: false },
  { name: 'entryProg', isTimeField: true },
  { name: 'exitProg', isTimeField: true },
  { name: 'totalHours', isTimeField: true },
  { name: 'breakStart', isTimeField: true },
  { name: 'breakEnd', isTimeField: true },
  { name: 'timeBreak', isTimeField: true },
  { name: 'netHours', isTimeField: true },
  { name: 'entryTolerance', isTimeField: true },
  { name: 'additionalsTolerance', isTimeField: true },
];

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (field) => {

      if (field.isTimeField) {
        if (isCreate) {
          return new Date(0, 0, 0, 0, 0);
        }
        const timeArr = data[field.name].split(":");
        return new Date(0, 0, 0, timeArr[0], timeArr[1]);
      }

      if (field.name === 'isProgrammable' || field.name === 'breakProg') {
        return isCreate ? true : data[field.name];
      }

      return isCreate ? "" : data[field.name];
    }

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName.name]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  // calculo de totalHours
  useEffect(() => {
    if (formData.isProgrammable) {
      let auxEndDate = new Date(formData.exitProg);
      if (formData.entryProg >= formData.exitProg) {
        auxEndDate.setDate(auxEndDate.getDate() + 1);
      }

      const timeDiff = Math.abs(auxEndDate - formData.entryProg);
      const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
      const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);

      setFormData((prev) => ({
        ...prev,
        'totalHours': new Date(0, 0, 0, hours, minutes),
      }))
    }
  }, [formData.isProgrammable, formData.entryProg, formData.exitProg])

  // calculo de timeBreak
  useEffect(() => {
    if (formData.breakProg) {
      let auxEndDate = new Date(formData.breakEnd);
      if (formData.breakStart >= formData.breakEnd) {
        auxEndDate.setDate(auxEndDate.getDate() + 1);
      }

      const timeDiff = Math.abs(auxEndDate - formData.breakStart);
      const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
      const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);

      setFormData((prev) => ({
        ...prev,
        'timeBreak': new Date(0, 0, 0, hours, minutes),
      }))
    }
  }, [formData.breakProg, formData.breakStart, formData.breakEnd])

  // calculo de netHours
  useEffect(() => {
      const timeDiff = Math.abs(formData.totalHours - formData.timeBreak);
      const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
      const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);

      setFormData((prev) => ({
        ...prev,
        'netHours': new Date(0, 0, 0, hours, minutes),
      }))
  }, [formData.totalHours, formData.timeBreak])

  const dataIsValid = async () => {

    const codeRegex = /^[A-Z]+[A-Z0-9_]*$/;

    const schema = yup.object().shape({
      'code': yup.string().max(12).matches(codeRegex).required(),
      'name': yup.string().max(60).required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      const formDataToSend = { ...formData };

      fieldNames.forEach(field => {
        if(field.isTimeField){
          formDataToSend[field.name] = formatDateHHMM(formDataToSend[field.name]);
        }
      })

      isCreate ? createCallback(formDataToSend) : modifyCallback(formDataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const formatValue = (value, fieldName) => {
    if (fieldName === "code") {
      return value.length > 0 ? value.replace(/\s/g, "").toUpperCase() : "";
    }

    return value;
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: formatValue(value, fieldName)
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
