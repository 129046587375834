import React from "react";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import useRebuildAccumulators from "./useRebuildAccumulators";
import { LocalAtmIcon } from "@icarius-icons";

const RebuildAccumulators = () => {

  const {
    rebuildAccumulatorsData,
    rebuildAccumulatorsFunctions,
  } = useRebuildAccumulators();

  const periodItem = (gridRef) => (
    <FormControl style={{ width: "300px", marginRight: 10 }}>
      <InputLabel shrink id={`label`}>{"Seleccionar periodo"}</InputLabel>
      <Select
        value={rebuildAccumulatorsData.period}
        labelId={`label`}
        id={`select`}
        onChange={(e) => rebuildAccumulatorsFunctions.handlePeriodChange(e.target.value, gridRef)}
        margin={"none"}
        displayEmpty
      >
        <MenuItem className={"whiteText"} value={''}>
          {'Todos'}
        </MenuItem>
        {
          rebuildAccumulatorsData.periodArray.map(item => (
            <MenuItem
              className={"whiteText"}
              key={item}
              value={item}
            >
              {item}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )

  const rebuildItem = () => (
    <CustomIconButton
      title={'Recomponer acumuladores del periodo'}
      onClick={rebuildAccumulatorsFunctions.handleRebuild}
    >
      <LocalAtmIcon />
    </CustomIconButton>
  )

  return (
    <CommonPage
      title={'Recomposición de acumuladores históricos'}
      gridTitle={'Recomposición de acumuladores históricos'}
      menuItems={[periodItem, rebuildItem]}
      rowData={rebuildAccumulatorsData.data}
      dateFormat={rebuildAccumulatorsData.dateFormat}
      isLoading={rebuildAccumulatorsData.isExportinGrid || rebuildAccumulatorsData.isLoading}
      columnDefPage={paths.rebuildAccumulators}
      hasExpand
      hasHelp
    />
  );
}

export default RebuildAccumulators;