import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getRecognitionAnalysisAction,
} from "../actions";
import {
  getDateFormat,
  getLoading,
  getData,
  getGroups,
} from "../selectors";
import { getIsExportingGrid } from "src/app/selectors";
import { useMemo } from "react";

const useRecognitionAnalysis = () => {

  const [onlyActives, setOnlyActives] = useState(true);
  const [groupSelectedKey, setGroupSelectedKey] = useState('');

  const dispatch = useDispatch();
  const data = useSelector(getData);
  const dateFormat = useSelector(getDateFormat);
  const groups = useSelector(getGroups);
  const isLoading = useSelector(getLoading);
  const isExportinGrid = useSelector(getIsExportingGrid);

  useEffect(() => {
    dispatch(getRecognitionAnalysisAction(onlyActives))
  }, [dispatch, onlyActives])

  const handleSwitchOnlyActives = () => setOnlyActives(prev => !prev);
  const handleSelectGroup = (newGroup) => setGroupSelectedKey(newGroup);

  const filteredData = useMemo(() => {
    if (!groupSelectedKey) return data;
    const groupSelected = groups.find((item) => item.id === groupSelectedKey);
    return data.filter((item) => groupSelected.participants.some((participant) => participant.key === item['Código de empleado']));
  }, [data, groupSelectedKey, groups])

  return {
    data: filteredData,
    dateFormat,
    isLoading: isLoading || isExportinGrid,
    onlyActives,
    groups,
    groupSelectedKey,
    handleSwitchOnlyActives,
    handleSelectGroup,
  }
}

export default useRecognitionAnalysis;
