import NAME from "./constants";

export const GET_REQUESTED_DOCUMENTS_ROWS = NAME + "/GET_REQUESTED_DOCUMENTS_ROWS";
export const GET_REQUESTED_DOCUMENTS_ROWS_FULFILLED = NAME + "/GET_REQUESTED_DOCUMENTS_ROWS_FULFILLED";
export const GET_REQUESTED_DOCUMENTS_ROWS_REJECTED = NAME + "/GET_REQUESTED_DOCUMENTS_ROWS_REJECTED";

export const SIGN_DOCUMENTS = NAME + "/SIGN_DOCUMENTS";
export const SIGN_DOCUMENTS_FULFILLED = NAME + "/SIGN_DOCUMENTS_FULFILLED";
export const SIGN_DOCUMENTS_REJECTED = NAME + "/SIGN_DOCUMENTS_REJECTED";

export const DOWNLOAD_MULTIPLE_DOCUMENTS = NAME + "/DOWNLOAD_MULTIPLE_DOCUMENTS";
export const DOWNLOAD_MULTIPLE_DOCUMENTS_FULFILLED = NAME + "/DOWNLOAD_MULTIPLE_DOCUMENTS_FULFILLED";
export const DOWNLOAD_MULTIPLE_DOCUMENTS_REJECTED = NAME + "/DOWNLOAD_MULTIPLE_DOCUMENTS_REJECTED";

export const DELETE_DOCUMENTS = NAME + "/DELETE_DOCUMENTS";
export const DELETE_DOCUMENTS_FULFILLED = NAME + "/DELETE_DOCUMENTS_FULFILLED";
export const DELETE_DOCUMENTS_REJECTED = NAME + "/DELETE_DOCUMENTS_REJECTED";