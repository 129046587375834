import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendMail, getRequestsTableRows, deleteRequestAction } from "../actions";
import { loading, getRequestsRows, getDateFormat, getLocale, } from "../selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import { getIsExportingGrid } from "src/app/selectors";
import ConfirmationDialog from "../../myPeople/components/dialog/confirmationDialog";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { openDialogAction } from "@icarius-common/dialog/actions";
import DeleteDialog from "./deleteDialog";

const Requests = () => {

  const [isHistorical, setIsHistorical] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [codes, setCodes] = useState(null);

  const dispatch = useDispatch();
  const requestsRows = useSelector(getRequestsRows);
  const dateFormat = useSelector(getDateFormat);
  const locale = useSelector(getLocale);
  const loadingRows = useSelector(loading);
  const exportingGridStatus = useSelector(getIsExportingGrid);

  useEffect(() => {
    dispatch(getRequestsTableRows(isHistorical));
  }, [dispatch, isHistorical])

  const handleSendMail = (gridRef) => {
    let selectedCodes = gridRef.api.getSelectedRows()
      .map(item => {
        return {
          email: item["responsible_email"],
          status: item["id_status"]
        }
      });

    if (selectedCodes.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
    } else {
      setDialogIsOpen(true);
      setCodes(selectedCodes);
    }
  };

  const handleOpenPreDeleteDialog = (gridRef) => {
    let selectedCodes = gridRef.api.getSelectedRows();

    if (selectedCodes.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
    } else if (selectedCodes.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un único item", severity: "warning" }));
    } else {
      dispatch(openDialogAction({
        title: 'Atención',
        msg: `¿Está seguro que desea eliminar la solicitud seleccionada que está en curso?`,
        onConfirm: () => {
          setDeleteDialogIsOpen(true);
          setCodes(selectedCodes);
        },
      }));
    }
  };

  const handleConfirm = (codes) => {
    dispatch(sendMail(codes))
      .then(() => dispatch(openSnackbarAction({ msg: getLocalizedString("mailsHaveBeenSent"), severity: "success" })));
  }

  const handleDelete = (data) => {
    dispatch(deleteRequestAction(data))
      .then(() => {
        dispatch(openSnackbarAction({ msg: "Solicitud eliminada con éxito", severity: "success" }));
        setDeleteDialogIsOpen(false);
        setCodes(null);
      });
  }

  const item = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("sendMessage")}
      onClick={() => handleSendMail(gridRef)}
      type={"mail"}
    />

  const deleteItem = (gridRef) =>
    <CustomIconButton
      title={"Eliminar"}
      onClick={() => handleOpenPreDeleteDialog(gridRef)}
      type={"delete"}
    />

  const customItems = [deleteItem, item];

  const helpItems = [
    <MenuItemWithIcon key={"a"} value={"/resources/ICARIUS - Solicitudes Colaborador.pdf"} text={getLocalizedString("requestsColabInstructivo")} type={"file"} />,
    <MenuItemWithIcon key={"2"} value={"https://www.youtube.com/embed/8fj60nDAJ4A"} text={getLocalizedString("requestsTitle")} type={"video"} />,
  ]

  return (
    <CommonPage
      helpItems={helpItems}
      menuItems={isHistorical ? null : customItems}
      rowData={requestsRows}
      title={getLocalizedString("requestsControlTitle")}
      gridTitle={getLocalizedString("requestsControlTitle")}
      locale={locale}
      dateFormat={dateFormat}
      columnDefPage={isHistorical ? paths.requestControlHistorical : paths.requestControl}
      isLoading={loadingRows || exportingGridStatus}
      hasHelp
      hasSelectAll
      hasExpand
      hasSwitch
      handleSwitchClick={() => setIsHistorical(prev => !prev)}
      switchLabelOff={'En curso'}
      switchLabelOn={'Historicas'}
      switchValue={isHistorical}
    >
      {
        dialogIsOpen &&
        <ConfirmationDialog
          isJustified={true}
          open={dialogIsOpen}
          handleConfirm={handleConfirm}
          handleClose={() => setDialogIsOpen(false)}
          code={codes}
          text={getLocalizedString("requestMailConfirm")}
        />
      }
      {
        deleteDialogIsOpen &&
        <DeleteDialog
          open={deleteDialogIsOpen}
          handleConfirm={handleDelete}
          handleClose={() => setDeleteDialogIsOpen(false)}
          requestData={codes[0]}
        />
      }
    </CommonPage>
  );
}

export default Requests;
