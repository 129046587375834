import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHistoricalProcessesAction, rebuildAccumulatorsAction } from "../actions";
import { getRows, getDateFormat, getLoading } from "../selectors";
import { getIsExportingGrid } from "src/app/selectors";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { openDialogAction } from "@icarius-common/dialog/actions";

const useRebuildAccumulators = () => {

  const [period, setPeriod] = useState('');

  const dispatch = useDispatch();

  const data = useSelector(getRows);
  const dateFormat = useSelector(getDateFormat);
  const isLoading = useSelector(getLoading);
  const isExportinGrid = useSelector(getIsExportingGrid);

  useEffect(() => {
    dispatch(getHistoricalProcessesAction());
  }, [dispatch])

  const periodArray = useMemo(() => {
    const periods = data.map(item => item['Período']);
    return [...new Set(periods)].sort((a, b) => a.localeCompare(b));
  }, [data])

  const handlePeriodChange = (value, gridRef) => {
    setPeriod(value);
    // obtener el filtro
    let periodFilter = gridRef.api.getFilterInstance("Período");

    if (value) {
      gridRef.api.forEachNode(node => {
        if (node.data['Período'] === value) {
          node.setSelected(true);
        }
      });

      // aplicar filtro
      periodFilter.setModel({ values: [value] });
      gridRef.api.onFilterChanged();
    } else {
      // deseleccionar todos
      gridRef.api.forEachNode(node => node.setSelected(false));

      // resetear filtro
      periodFilter.resetFilterValues();
      gridRef.api.onFilterChanged();
    }
  }

  const handleRebuild = () => {
    if (!period) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un periodo', severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: 'Atención',
      msg: `¿Está seguro que desea recomponer los acumuladores históricos del período ${period}`,
      onConfirm: () => {
        dispatch(openSnackbarAction({ msg: `Recomponiendo acumuladores históricos para ${period}`, severity: "info" }));
        dispatch(rebuildAccumulatorsAction(period))
      },
    }));
  }

  const rebuildAccumulatorsData = {
    period,
    data,
    dateFormat,
    isLoading,
    isExportinGrid,
    periodArray,
  }

  const rebuildAccumulatorsFunctions = {
    handleRebuild,
    handlePeriodChange,
  }

  return {
    rebuildAccumulatorsData,
    rebuildAccumulatorsFunctions,
  }
}

export default useRebuildAccumulators;