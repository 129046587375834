import {
  GET_EMPLOYEE,
  GET_EMPLOYEE_FULFILLED,
  GET_EMPLOYEE_REJECTED,
  GET_PEOPLE_ROWS,
  GET_PEOPLE_ROWS_FULFILLED,
  GET_PEOPLE_ROWS_REJECTED,
  DOWNLOAD_MULTIPLE_RECEIPT,
  DOWNLOAD_MULTIPLE_RECEIPT_FULFILLED,
  DOWNLOAD_MULTIPLE_RECEIPT_REJECTED,
  GET_COLABORATORS_FOR_RECEIPT,
  GET_COLABORATORS_FOR_RECEIPT_FULFILLED,
  GET_COLABORATORS_FOR_RECEIPT_REJECTED,
  SIGN_AND_DOWNLOAD,
  SIGN_AND_DOWNLOAD_REJECTED,
  SIGN_AND_DOWNLOAD_FULFILLED,
  RESTORE_DEFAULT_STATE,
} from "./actionTypes";

const initialState = {
  peopleRows: [],
  locale: "es",
  dateFormat: "yyyy/mm/dd",
  gettingPeopleRows: false,
  gettingColaboratorsForReceipt: false,
  helpDocumentation: "",
  likeRequest: false,
  gettingReceipt: false,
  calculationProcesses: [],
  receipts: [],
  signing: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SIGN_AND_DOWNLOAD:
      return { ...state, signing: true };
    case SIGN_AND_DOWNLOAD_FULFILLED:
      let newDocuments = [...state.peopleRows]
      let generatedDocuments = action.payload.docs;

      newDocuments = newDocuments.filter(doc => !generatedDocuments.find(genDoc => (
        doc.dataDoc.code === genDoc.code &&
        doc.dataDoc.tipoDoc === genDoc.tipoDoc &&
        doc.dataDoc.codRef === genDoc.codRef &&
        doc.dataDoc.dataCode === genDoc.dataCode &&
        doc.dataDoc.destinataries === genDoc.destinataries &&
        doc.dataDoc.approvants === genDoc.approvants))
      );
      return {
        ...state,
        signing: false,
        peopleRows: newDocuments,
      };
    case SIGN_AND_DOWNLOAD_REJECTED:
      return { ...state, signing: false };

    case GET_PEOPLE_ROWS:
      return { ...state, gettingPeopleRows: true };
    case GET_PEOPLE_ROWS_FULFILLED:
      return {
        ...state,
        gettingPeopleRows: false,
        peopleRows: action.payload.colaborators,
        locale: action.payload.locale,
        dateFormat: action.payload.dateFormat,
        totalEmployeesCount: action.payload.totalCount,
        helpDocumentation: action.payload.helpDocumentation,
        calculationProcesses: action.payload.calculation_processes,
        receipts: action.payload.receipts,
      };
    case GET_PEOPLE_ROWS_REJECTED:
      return { ...state, gettingPeopleRows: false };
    case GET_COLABORATORS_FOR_RECEIPT:
      return { ...state, gettingColaboratorsForReceipt: true };
    case GET_COLABORATORS_FOR_RECEIPT_FULFILLED:
      return {
        ...state,
        gettingColaboratorsForReceipt: false,
        peopleRows: action.payload.colaborators,
        locale: action.payload.locale,
        dateFormat: action.payload.dateFormat,
        helpDocumentation: action.payload.helpDocumentation,
      };
    case GET_COLABORATORS_FOR_RECEIPT_REJECTED:
      return { ...state, gettingColaboratorsForReceipt: false };
    case GET_EMPLOYEE:
      return {
        ...state,
        gettingEmployee: true,
        employeeCode: action.payload,
      };
    case GET_EMPLOYEE_FULFILLED:
      return {
        ...state,
        gettingEmployee: false,
        employees: { [action.payload.CodigoEmpleado]: action.payload },
      };
    case GET_EMPLOYEE_REJECTED:
      return { ...state, gettingEmployee: false };
    case DOWNLOAD_MULTIPLE_RECEIPT:
      return { ...state, gettingReceipt: true };
    case DOWNLOAD_MULTIPLE_RECEIPT_FULFILLED:
      return {
        ...state,
        gettingReceipt: false,
      };
    case DOWNLOAD_MULTIPLE_RECEIPT_REJECTED:
      return { ...state, gettingReceipt: false };
    case RESTORE_DEFAULT_STATE:
      return initialState;
    default:
      return state;
  }
}
