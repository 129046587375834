import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === 'specificWeight') {
        return isCreate ? "1" : data[fieldName];
      }

      if (fieldName === 'status') {
        return isCreate ? "A" : data[fieldName];
      }

      if (
        [
          "pointValue",
          "maxBudget",
          "toMedalBronzePoints",
          "toMedalSilverPoints",
          "toMedalGoldPoints",
          "toShieldBronzePoints",
          "toShieldSilverPoints",
          "toShieldGoldPoints",
          "percentMoneyChangeMedalBronze",
          "percentMoneyChangeMedalSilver",
          "percentMoneyChangeMedalGold",
          "percentMoneyChangeShieldBronze",
          "percentMoneyChangeShieldSilver",
          "percentMoneyChangeShieldGold",
        ].includes(fieldName)) {
        return isCreate ? "0" : data[fieldName];
      }

      if (
        [
          "obligationChangeMedalBronze",
          "obligationChangeMedalSilver",
          "obligationChangeMedalGold",
          "obligationChangeShieldBronze",
          "obligationChangeShieldSilver",
          "obligationChangeShieldGold",
          "annotationRecordMedalBronze",
          "annotationRecordMedalSilver",
          "annotationRecordMedalGold",
          "annotationRecordShieldBronze",
          "annotationRecordShieldSilver",
          "annotationRecordShieldGold",
          "diffusionMedalBronze",
          "diffusionMedalSilver",
          "diffusionMedalGold",
          "diffusionShieldBronze",
          "diffusionShieldSilver",
          "diffusionShieldGold",
          "priceAwardingMedalBronze",
          "priceAwardingMedalSilver",
          "priceAwardingMedalGold",
          "priceAwardingShieldBronze",
          "priceAwardingShieldSilver",
          "priceAwardingShieldGold",
          "changePointsMedalBronze",
          "changePointsMedalSilver",
          "changePointsMedalGold",
          "changePointsShieldBronze",
          "changePointsShieldSilver",
          "changePointsShieldGold",
        ].includes(fieldName)) {
        return isCreate ? "N" : data[fieldName];
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      "code",
      "name",
      "pointValue",
      "specificWeight",
      "status",
      "maxBudget",
      "toMedalBronzePoints",
      "toMedalSilverPoints",
      "toMedalGoldPoints",
      "toShieldBronzePoints",
      "toShieldSilverPoints",
      "toShieldGoldPoints",
      "changePointsMedalBronze",
      "changePointsMedalSilver",
      "changePointsMedalGold",
      "changePointsShieldBronze",
      "changePointsShieldSilver",
      "changePointsShieldGold",
      "priceAwardingMedalBronze",
      "priceAwardingMedalSilver",
      "priceAwardingMedalGold",
      "priceAwardingShieldBronze",
      "priceAwardingShieldSilver",
      "priceAwardingShieldGold",
      "diffusionMedalBronze",
      "diffusionMedalSilver",
      "diffusionMedalGold",
      "diffusionShieldBronze",
      "diffusionShieldSilver",
      "diffusionShieldGold",
      "annotationRecordMedalBronze",
      "annotationRecordMedalSilver",
      "annotationRecordMedalGold",
      "annotationRecordShieldBronze",
      "annotationRecordShieldSilver",
      "annotationRecordShieldGold",
      "obligationChangeMedalBronze",
      "obligationChangeMedalSilver",
      "obligationChangeMedalGold",
      "obligationChangeShieldBronze",
      "obligationChangeShieldSilver",
      "obligationChangeShieldGold",
      "percentMoneyChangeMedalBronze",
      "percentMoneyChangeMedalSilver",
      "percentMoneyChangeMedalGold",
      "percentMoneyChangeShieldBronze",
      "percentMoneyChangeShieldSilver",
      "percentMoneyChangeShieldGold",
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const stepOneIsValid = async () => {
    const codeRegex = /^[a-zA-Z]+[a-zA-Z0-9_]*$/;

    const schema = yup.object().shape({
      'code': yup.string().matches(codeRegex),
      'name': yup.string().required(),
      'pointValue': yup.number().min(0).required(),
      'maxBudget': yup.number().min(0).nullable(true).transform((_, val) => val ? Number(val) : null),
      'specificWeight': yup.number().min(1).required(),
      'status': yup.string().required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const stepTwoIsValid = async () => {
    const schema = yup.object().shape({
      "toMedalBronzePoints": yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null),
      "toMedalSilverPoints": yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null),
      "toMedalGoldPoints": yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null),
      "toShieldBronzePoints": yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null),
      "toShieldSilverPoints": yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null),
      "toShieldGoldPoints": yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null),
      "changePointsMedalBronze": yup.string().required(),
      "changePointsMedalSilver": yup.string().required(),
      "changePointsMedalGold": yup.string().required(),
      "changePointsShieldBronze": yup.string().required(),
      "changePointsShieldSilver": yup.string().required(),
      "changePointsShieldGold": yup.string().required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const stepThreeIsValid = async () => {
    const schema = yup.object().shape({
      "percentMoneyChangeMedalBronze": yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null),
      "percentMoneyChangeMedalSilver": yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null),
      "percentMoneyChangeMedalGold": yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null),
      "percentMoneyChangeShieldBronze": yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null),
      "percentMoneyChangeShieldSilver": yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null),
      "percentMoneyChangeShieldGold": yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null),
      "obligationChangeMedalBronze": yup.string().required(),
      "obligationChangeMedalSilver": yup.string().required(),
      "obligationChangeMedalGold": yup.string().required(),
      "obligationChangeShieldBronze": yup.string().required(),
      "obligationChangeShieldSilver": yup.string().required(),
      "obligationChangeShieldGold": yup.string().required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const stepFourIsValid = async () => {
    const schema = yup.object().shape({
      "priceAwardingMedalBronze": yup.string().required(),
      "priceAwardingMedalSilver": yup.string().required(),
      "priceAwardingMedalGold": yup.string().required(),
      "priceAwardingShieldBronze": yup.string().required(),
      "priceAwardingShieldSilver": yup.string().required(),
      "priceAwardingShieldGold": yup.string().required(),
      "diffusionMedalBronze": yup.string().required(),
      "diffusionMedalSilver": yup.string().required(),
      "diffusionMedalGold": yup.string().required(),
      "diffusionShieldBronze": yup.string().required(),
      "diffusionShieldSilver": yup.string().required(),
      "diffusionShieldGold": yup.string().required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      "annotationRecordMedalBronze": yup.string().required(),
      "annotationRecordMedalSilver": yup.string().required(),
      "annotationRecordMedalGold": yup.string().required(),
      "annotationRecordShieldBronze": yup.string().required(),
      "annotationRecordShieldSilver": yup.string().required(),
      "annotationRecordShieldGold": yup.string().required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      isCreate ? createCallback(formData) : modifyCallback({ ...formData, internalCode: data.internalCode });
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return {
    isCreate,
    formData,
    setFormValue,
    submit,
    stepOneIsValid,
    stepTwoIsValid,
    stepThreeIsValid,
    stepFourIsValid,
  };
}

export default useHandleForm;
