import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { deleteSchedulesProgrammingAction, getSchedulesProgrammingAction } from "../actions";
import { getRows, getLoading } from "../selectors";
import { CLEAR_STATE } from "../actionTypes";
import { getIsExportingGrid } from "src/app/selectors";
import { openDialogAction } from "@icarius-common/dialog/actions";

const useSchedulesProgramming = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSchedulesProgrammingAction());
    return () => {
      dispatch({ type: CLEAR_STATE });
    }
  }, [dispatch])

  const data = useSelector(getRows);
  const isLoading = useSelector(getLoading);
  const isExportinGrid = useSelector(getIsExportingGrid);

  const [treeViewIsOpen, setTreeViewIsOpen] = useState(false);
  const [createEditDialogIsOpen, setCreateEditDialogIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleOpenCreateDialog = () => {
    setCreateEditDialogIsOpen(true);
  }

  const handleOpenEditDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un unico item", severity: "warning" }));
      return;
    }

    setCreateEditDialogIsOpen(true);
    setSelectedItem(selectedRows[0]);
  };

  const handleCloseEditDialog = () => {
    setCreateEditDialogIsOpen(false);
    setSelectedItem(null);
  }

  const handleOpenDeleteDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un unico item", severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: "Eliminar programación",
      msg: "¿Desea eliminar la programación de horario?",
      onConfirm: () => handleDelete(selectedRows.map(item => item.internalCode)[0]),
    }));
  }

  const handleDelete = (codeToDelete) => {
    dispatch(deleteSchedulesProgrammingAction({ internalCode: codeToDelete }));
  }

  const handleGrouping = (gridRef) => {
    if (!treeViewIsOpen) {
      gridRef.columnApi.applyColumnState({
        state: [
          {
            aggFunc: null,
            colId: "ag-Grid-AutoColumn",
            flex: null,
            hide: false,
            pivot: false,
            pivotIndex: null,
            rowGroup: false,
            rowGroupIndex: null,
            sort: "asc",
            sortIndex: 0,
            width: 400,
          },
          {
            colId: 'turnCodeString',
            rowGroup: true,
            hide: true,
          },
        ]
      });
      gridRef.api.expandAll();
    } else {
      gridRef.columnApi.applyColumnState({
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          hide: false,
          sort: null,
          rowGroup: null,
          pivot: null,
          pinned: null
        }
      });
    }
    setTreeViewIsOpen(prev => !prev);
  }

  return {
    data,
    isLoading,
    isExportinGrid,
    createEditDialogIsOpen,
    treeViewIsOpen,
    selectedItem,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseEditDialog,
    handleOpenDeleteDialog,
    handleGrouping,
  }
}

export default useSchedulesProgramming;
