import paths from "@icarius-localization/paths";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
	numberComparatorMax2,
	numberFormatterMin2,
	numberFormatterMin0Max0,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: ['recognitionPlansDetail'],
        config: {
            headerName: 'Tipo',
            field: 'type',
            filter: "agSetColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ['recognitionPlansDetail'],
        config: {
            headerName: 'Puntos para obtenerlos',
            field: 'points',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['recognitionPlansDetail'],
        config: {
            headerName: 'Valor de cada reconocimiento',
            field: 'value',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['recognitionPlansDetail'],
        config: {
            headerName: '% máximo para canje por dinero',
            field: 'percentage',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['recognitionPlansDetail'],
        config: {
            headerName: 'Valor máximo para canje por dinero',
            field: 'maxValue',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['recognitionPlansDetail'],
        config: {
            headerName: 'Canje de puntos',
            field: 'change',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['recognitionPlansDetail'],
        config: {
            headerName: 'Entrega de premio',
            field: 'priceAwarding',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['recognitionPlansDetail'],
        config: {
            headerName: 'Difusión',
            field: 'diffusion',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['recognitionPlansDetail'],
        config: {
            headerName: 'Registro de incidentes críticos (Anotaciones)',
            field: 'annotations',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['recognitionPlansDetail'],
        config: {
            headerName: 'Obligatoriedad de canje',
            field: 'required',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.recognitionPlans],
        config: {
            headerName: 'Código del plan',
            field: 'code',
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.recognitionPlans],
        config: {
            headerName: 'Nombre del plan',
            field: 'name',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.recognitionPlans],
        config: {
            headerName: 'Estado del plan',
            field: 'statusString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.recognitionPlans],
        config: {
            headerName: 'Peso específico',
            field: 'specificWeight',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.recognitionPlans],
        config: {
            headerName: 'Valor de cada punto',
            field: 'pointValue',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.recognitionPlans],
        config: {
            headerName: 'Presupuesto máximo del plan',
            field: 'maxBudget',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['collaboratorsInPlans'],
        config: {
            headerName: 'Codigo de plan',
            field: 'Codigo de plan',
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ['collaboratorsInPlans'],
        config: {
            headerName: 'Nombre del Plan',
            field: 'Nombre del Plan',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['collaboratorsInPlans'],
        config: {
            headerName: 'Estado del plan',
            field: 'Estado del plan',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['collaboratorsInPlans'],
        config: {
            headerName: 'Código de empleado',
            field: 'Código de empleado',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['collaboratorsInPlans'],
        config: {
            headerName: 'Apellido y nombres',
            field: 'Apellido y nombres',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['collaboratorsInPlans'],
        config: {
            headerName: 'Estado empleado en el plan',
            field: 'Estado empleado en el plan',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['collaboratorsInPlans'],
        config: {
            headerName: 'Fecha de asignación al plan',
            field: 'Fecha de asignación al plan',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: ['collaboratorsInPlans'],
        config: {
            headerName: 'Tipo de funcionario',
            field: 'Tipo de funcionario',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['collaboratorsInPlans'],
        config: {
            headerName: 'Cargo',
            field: 'Cargo',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['collaboratorsInPlans'],
        config: {
            headerName: 'Gerencia',
            field: 'Gerencia',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['collaboratorsInPlans'],
        config: {
            headerName: 'Centro de costo de gestión',
            field: 'Centro de costo de gestión',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['collaboratorsInPlans'],
        config: {
            headerName: 'Tipo de contrato',
            field: 'Tipo de contrato',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['collaboratorsInPlans'],
        config: {
            headerName: 'Fecha de contratación',
            field: 'Fecha de contratación',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: ['collaboratorsInPlans'],
        config: {
            headerName: 'Lugar de trabajo',
            field: 'Lugar de trabajo',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['collaboratorsInPlans'],
        config: {
            headerName: 'Obra o Faena',
            field: 'Obra o Faena',
            filter: "agSetColumnFilter",
        }
    },
    // benefitRecognitionPlansDetail
    {
        pages: ['benefitRecognitionPlansDetail'],
        config: {
            headerName: 'Código de empleado',
            field: 'Código de empleado',
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ['benefitRecognitionPlansDetail'],
        config: {
            headerName: 'Apellido y Nombres',
            field: 'Apellido y Nombres',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['benefitRecognitionPlansDetail'],
        config: {
            headerName: 'Estado',
            field: 'Estado',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['benefitRecognitionPlansDetail'],
        config: {
            headerName: 'Fecha de solicitud',
            field: 'Fecha de solicitud',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: ['benefitRecognitionPlansDetail'],
        config: {
            headerName: 'Puntos canjeados',
            field: 'Puntos canjeados',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['benefitRecognitionPlansDetail'],
        config: {
            headerName: 'Tipo de canje',
            field: 'Tipo de canje',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['benefitRecognitionPlansDetail'],
        config: {
            headerName: 'Beneficio',
            field: 'Beneficio',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['benefitRecognitionPlansDetail'],
        config: {
            headerName: 'Cantidad',
            field: 'Cantidad',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['benefitRecognitionPlansDetail'],
        config: {
            headerName: 'Valor del beneficio',
            field: 'Valor del beneficio',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },

]