import React from "react";
import ScreenSelector from "@icarius-common/screenSelector";
import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import { PermissionsIcon, AdvancesIcon } from "@icarius-icons/";

const RequestsSettings = () => {

  const data = [
    {
      name: "Permisos para solicitudes",
      icon: <PermissionsIcon />,
      path: paths.permissionsSettings,
    },
    {
      name: "Anticipos para solicitudes",
      icon: <AdvancesIcon />,
      path: paths.advancesSettings,
    },
  ];

  return (
    <ScreenSelector
      title={getLocalizedString("requestsSettings")}
      data={data}
    />
  );
}

export default RequestsSettings;
