import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import CreateEditFormContainer from "./form/formContainer";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import useABM from "./useABM";
import paths from "@icarius-localization/paths";

const SchedulesDefinition = ({ history }) => {

  const {
    data,
    isLoading,
    isExportinGrid,
    createEditDialogIsOpen,
    selectedItem,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseEditDialog,
    handleOpenDeleteDialog,
  } = useABM();

  const addItem = () =>
    <CustomIconButton
      title={"Crear"}
      onClick={() => handleOpenCreateDialog()}
      type={"add"}
    />

  const deleteItem = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />

  const editItem = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handleOpenEditDialog(gridRef)}
      type={"edit"}
    />

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => history.push(paths.timeDefinition)}
      type={"goBack"}
    />

  return (
    <>
      {
        createEditDialogIsOpen ?
          <CreateEditFormContainer
            isLoading={isLoading}
            data={selectedItem}
            handleClose={handleCloseEditDialog}
          />
          :
          <CommonPage
            title={'Definición de horarios'}
            gridTitle={'Definición de horarios'}
            menuItems={[goBackItem, addItem, editItem, deleteItem]}
            columnDefPage={paths.schedulesDefinition}
            rowData={data}
            isLoading={isExportinGrid || isLoading}
            hasExpand
            hasHelp
          />
      }
    </>
  );
}

export default SchedulesDefinition;
