import * as actionTypes from "./actionTypes";
import {
  getRecognitionPlansApi,
  createRecognitionPlanApi,
  updateRecognitionPlanApi,
  deleteRecognitionPlanAPI,
  duplicateRecognitionPlanApi,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      errorString = 'Ya existe un plan con el mismo código y nombre';
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getRecognitionPlansAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getRecognitionPlansApi();

    let data = response.data && response.data.data;
    let statusList = response.data && response.data.statusList;
    let changePointsList = response.data && response.data.changePointsList;
    let priceAwardingList = response.data && response.data.priceAwardingList;
    let diffusionList = response.data && response.data.diffusionList;
    let annotationList = response.data && response.data.annotationList;
    let collaboratorsInPlans = response.data && response.data.collaboratorsInPlans;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        data,
        statusList,
        changePointsList,
        priceAwardingList,
        diffusionList,
        annotationList,
        collaboratorsInPlans,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createRecognitionPlanAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createRecognitionPlanApi(dataToSend);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Plan creado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const updateRecognitionPlanAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await updateRecognitionPlanApi(dataToSend);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;
    
    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }
    
    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { data },
    });
    
    dispatch(openSnackbarAction({ msg: 'Plan actualizado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteRecognitionPlanAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteRecognitionPlanAPI(dataToSend);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;
    
    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }
    
    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data },
    });
    
    dispatch(openSnackbarAction({ msg: 'Plan eliminado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const duplicateRecognitionPlanAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DUPLICATE });
  try {
    let response = await duplicateRecognitionPlanApi(dataToSend);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DUPLICATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DUPLICATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Plan creado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DUPLICATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};