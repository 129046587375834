import React from "react";
import { Chart } from "react-chartjs-2";
import { material } from "@icarius-utils/colors";
import { formatNumberOrReturnUndefined } from "@icarius-utils/format";

class VerticalBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {
    this.myChart.update();
  }

  componentDidMount() {
    const that = this;
    let informacion = {
      labels: Object.keys(this.props.data),
      datasets: [
        {
          pointHoverRadius: 5,
          pointRadius: 3,
          fontColor: "#00FF00",
          pointHitRadius: 10,
          data: Object.values(this.props.data),
          borderColor: material.strokes,
          backgroundColor: material.fills,
          borderWidth: 1,
        },
      ],
    };

    const options = {
      onClick: function (e) {
        if (that.props.type === "pie" && this.getElementsAtEvent(e) && this.getElementsAtEvent(e).length > 0) {
          let activePointLabel = this.getElementsAtEvent(e)[0]._model.label;
          that.props.onClick(activePointLabel);
        }
      },
      responsive: true,
      title: {
        display: true,
        fontColor: this.props.theme === "dark" ? "white" : "black",
        fontSize: 12,
        text: this.props.title,
      },
      maintainAspectRatio: false,
      legend: {
        display: this.props.type !== "bar",
        position: "bottom",
        labels: {
          fontColor: this.props.theme === "dark" ? "white" : "black",
          fontSize: 12,
        },
      },
      layout: {
        padding: {
          bottom: 0,
          top: 0,
        },
      },
      tooltips: {
        displayColors: false,
        mode: "single",
        callbacks: {
          label: function (tooltipItem, data) {
            return that.props.type === "pie" ? `${data.labels[tooltipItem.index]}: ${formatNumberOrReturnUndefined(data.datasets[0].data[tooltipItem.index], 2)}` : formatNumberOrReturnUndefined(data.datasets[0].data[tooltipItem.index], 2);
          },
        },
      },
      scales:
        this.props.type === "bar"
          ? {
            xAxes: [
              {
                ticks: {
                  fontColor: this.props.theme === "dark" ? "white" : "black",
                  beginAtZero: true,
                  min: 0
                },
                gridLines: {
                  color:
                    this.props.theme === "dark"
                      ? "rgba(255, 255, 255, 0.5)"
                      : "rgba(1, 1, 1, 0.2)",
                  zeroLineColor: "transparent",
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: this.props.theme === "dark" ? "white" : "black",
                  beginAtZero: true,
                  callback: function (value, index, values) {
                    return formatNumberOrReturnUndefined(value, 0, 2);
                  }
                },
                gridLines: {
                  color:
                    this.props.theme === "dark"
                      ? "rgba(255, 255, 255, 0.5)"
                      : "rgba(1, 1, 1, 0.2)",
                  zeroLineColor: "transparent",
                },
              },
            ],
          } : {}
    };

    this.myChart = new Chart(this.canvasRef.current, {
      type: this.props.type,
      options: options,
      data: informacion,
    });
  }

  render() {
    return (
      <div
        className={"container h-100 sm:h-100 relative flex flex-col justify-center items-center"}>
        <div className="principal chart-wrapper">
          <div
            style={{ height: 300 }}
            className="container  h-100 sm:h-100  justify-between items-center">
            <canvas ref={this.canvasRef} />
          </div>
        </div>
      </div>
    );
  }
}

export default VerticalBarChart;
