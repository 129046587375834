import React from "react";
import ScreenSelector from "@icarius-common/screenSelector";
import paths from "@icarius-localization/paths";
import {
  RecognitionPlansIcon,
  BenefitsForPlansIcon,
  RecognitionAnalysisIcon,
} from "@icarius-icons/";

const RecognitionScreenSelector = () => {

  const data = [
    {
      name: "Beneficios para planes",
      icon: <BenefitsForPlansIcon />,
      path: paths.benefitsForPlans,
    },
    {
      name: "Planes de reconocimiento",
      icon: <RecognitionPlansIcon />,
      path: paths.recognitionPlans,
    },
    {
      name: "Análisis de reconocimientos",
      icon: <RecognitionAnalysisIcon />,
      path: paths.recognitionAnalysis,
    },
  ];

  return (
    <ScreenSelector
      title={'Reconocimientos'}
      data={data}
    />
  );
}

export default RecognitionScreenSelector;
