import { IMAGES_ENDPOINT_NO_STATIC } from "@icarius-connection/endpoints";
import paths from "@icarius-localization/paths";
import { PRIZE_TYPES } from "@icarius-pages/recognitionPlans/constants";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
	numberComparatorMax2,
	numberFormatterMin0Max0,
	numberFilterParams,
} from "@icarius-table/number";

const createHeaderTemplate = (type) => {

    let imgTag = `<img src=${IMAGES_ENDPOINT_NO_STATIC + 'images/recognition/' + type}.png alt=${PRIZE_TYPES[type].name} style="height: 30px" />`;

    return (
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
        imgTag +
        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
        '  </div>' +
        '</div>'
    )
}

export default [
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Nombre del plan",
            field: "Nombre del plan",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Estado del plan",
            field: "Estado del plan",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Foto del colaborador",
            field: "Foto del colaborador",
            filter: "agTextColumnFilter",
            cellRenderer: "AvatarRenderer",
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Código de empleado",
            field: "Código de empleado",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Apellidos y nombres",
            field: "Apellidos y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Puntos acumulados",
            field: "Puntos acumulados",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Estado empleado en el plan",
            field: "Estado empleado en el plan",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Fecha de asignación el plan",
            field: "Fecha de asignación el plan",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Última fecha de reconocimiento",
            field: "Última fecha de reconocimiento",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Medallas de bronce",
            field: "Medallas de bronce",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
            headerComponentParams: {
                template: createHeaderTemplate('bronzeMedal')
            }
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Medallas de plata",
            field: "Medallas de plata",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
            headerComponentParams: {
                template: createHeaderTemplate('silverMedal')
            }
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Medallas de oro",
            field: "Medallas de oro",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
            headerComponentParams: {
                template: createHeaderTemplate('goldMedal')
            }
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Escudos de bronce",
            field: "Escudos de bronce",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
            headerComponentParams: {
                template: createHeaderTemplate('bronzeShield')
            }
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Escudos de plata",
            field: "Escudos de plata",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
            headerComponentParams: {
                template: createHeaderTemplate('silverShield')
            }
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Escudos de oro",
            field: "Escudos de oro",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
            headerComponentParams: {
                template: createHeaderTemplate('goldShield')
            }
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Ranking",
            field: "Ranking",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
            cellRenderer: 'RatingRenderer',
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Cantidad total de reconocimientos",
            field: "Cantidad total de reconocimientos",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Tipo de funcionario",
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Cargo",
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Función",
            field: "Función",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Gerencia",
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Departamento",
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Centro de costo de gestión",
            field: "Centro de costo de gestión",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Tipo de contrato",
            field: "Tipo de contrato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Fecha de contratación",
            field: "Fecha de contratación",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Lugar de trabajo",
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Obra o Faena",
            field: "Obra o Faena",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Sexo",
            field: "Sexo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Edad",
            field: "Edad",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Grupo etario",
            field: "Grupo etario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "Nacionalidad",
            field: "Nacionalidad",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.recognitionAnalysis],
        config: {
            headerName: "País de residencia",
            field: "País de residencia",
            filter: "agSetColumnFilter",
        }
    },
]