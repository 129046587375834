import React from "react";
import { withStyles } from "@material-ui/core/styles";
import BarChart from "./BarChart";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";

const Charts = (props) => {

  const {
    dataToChart,
    theme,
    color,
    handleOpenStatsDialog,
  } = props;

  const ModifiedCard = withStyles({
    root: {
      marginBottom: "1vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "&:last-child": {
        marginLeft: 5,
      },
      backgroundColor: theme === "light" && "var(--secondaryBackgroundColor)",
    },
  })(Card);

  const ModifiedCardContent = withStyles({
    root: {
      width: "100%",
      padding: 4,
      "&:last-child": {
        paddingBottom: 4,
      },
    },
  })(CardContent);

  const { benefitsStats, maxBudget, spentValue } = dataToChart;

  const chartStats = benefitsStats.reduce((auxChartStats, item) => {
    auxChartStats[item.value] = item.count;
    return auxChartStats;
  }, {});

  return (
    <div style={{ width: "100%" }} className="container relative flex flex-row justify-between items-center">
      <Grid container direction="row">
        <Grid
          item
          xs={12}
          sm={Object.values(chartStats).length === 0 ? 12 : 6}
          style={Object.values(chartStats).length > 0 ? { marginTop: 23 } : {}}
        >
          <Typography className="whiteText">{""}</Typography>
          <ModifiedCard>
            <ModifiedCardContent>
              <BarChart
                dataType={"bar"}
                color={color}
                theme={theme}
                type={"bar"}
                data={{
                  "Máximo": parseFloat(maxBudget),
                  "Utilizado": parseFloat(spentValue),
                  "Disponible": parseFloat(maxBudget) - parseFloat(spentValue),
                }}
                title={"Presupuesto del Plan"}
              />
            </ModifiedCardContent>
          </ModifiedCard>
        </Grid>
        {
          Object.values(chartStats).length > 0 && handleOpenStatsDialog &&
          <Grid item xs={12} sm={6}>
            <Typography className="whiteText" style={{ textAlign: "center" }}>
              {"Click en el gráfico para ver el detalle"}
            </Typography>
            <ModifiedCard>
              <ModifiedCardContent>
                <BarChart
                  dataType={"pie"}
                  color={color}
                  theme={theme}
                  type={"pie"}
                  title={"Beneficios utilizados"}
                  data={chartStats}
                  onClick={(value) => handleOpenStatsDialog(value)}
                />
              </ModifiedCardContent>
            </ModifiedCard>
          </Grid>
        }
      </Grid>
    </div>
  );
}

export default Charts;
