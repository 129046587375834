import { useState } from "react";
import * as yup from 'yup';

const useHandleFormDuplicate = (data, duplicateCallback, invalidDataCallback) => {

  const createInitialData = () => {

    const getInitialFieldData = () => {
      return "";
    }

    const fieldNames = [
      'code',
      'name',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const codeRegex = /^[a-zA-Z]+[a-zA-Z0-9_]*$/;

    const schema = yup.object().shape({
      'code': yup.string().matches(codeRegex),
      'name': yup.string().required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {

      duplicateCallback({ ...formData, ref: data.code });
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { formData, setFormValue, submit };
}

export default useHandleFormDuplicate;
