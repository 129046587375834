import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
	numberComparatorMax2,
	numberFormatterMin2,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.requestControlHistorical],
        config: {
            headerName: getLocalizedString("referenceCode"),
            field: "code",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.requestControlHistorical],
        config: {
            headerName: getLocalizedString("type"),
            field: "type",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.requestControlHistorical],
        config: {
            headerName: getLocalizedString("subtype"),
            field: "subtype",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.requestControlHistorical],
        config: {
            headerName: getLocalizedString("requester"),
            field: "appealer_name",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.requestControlHistorical],
        config: {
            headerName: getLocalizedString("status"),
            field: "status",
            filter: "agSetColumnFilter",
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.requestControlHistorical],
        config: {
            headerName: getLocalizedString("requestBy"),
            field: "issuer_name",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.requestControlHistorical],
        config: {
            headerName: getLocalizedString("amountOfDays"),
            field: "duration_days",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.requestControlHistorical],
        config: {
            headerName: getLocalizedString("startDate"),
            field: "start_date",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            enableRowGroup: true,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.requestControlHistorical],
        config: {
            headerName: getLocalizedString("endDate"),
            field: "end_date",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            enableRowGroup: true,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.requestControlHistorical],
        config: {
            headerName: getLocalizedString("timePerDay"),
            field: "time_per_day",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.requestControlHistorical],
        config: {
            headerName: getLocalizedString("value"),
            field: "value",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.requestControlHistorical],
        config: {
            headerName: getLocalizedString("comments"),
            field: "comments",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.requestControl],
        config: {
            headerName: getLocalizedString("referenceCode"),
            field: "code",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.requestControl],
        config: {
            headerName: getLocalizedString("type"),
            field: "type",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.requestControl],
        config: {
            headerName: getLocalizedString("subtype"),
            field: "subtype",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.requestControl],
        config: {
            headerName: getLocalizedString("requester"),
            field: "appealer_name",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.requestControl],
        config: {
            headerName: getLocalizedString("status"),
            field: "status",
            filter: "agSetColumnFilter",
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.requestControl],
        config: {
            headerName: getLocalizedString("requestBy"),
            field: "issuer_name",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.requestControl],
        config: {
            headerName: getLocalizedString("phone"),
            field: "issuer_phone",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.requestControl],
        config: {
            headerName: getLocalizedString("email"),
            field: "issuer_email",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.requestControl],
        config: {
            headerName: getLocalizedString("responsibleName"),
            field: "responsible_name",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.requestControl],
        config: {
            headerName: getLocalizedString("responsiblePhone"),
            field: "responsible_phone",
            enableRowGroup: true,
            enableValue: true,
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.requestControl],
        config: {
            headerName: getLocalizedString("responsibleEmail"),
            field: "responsible_email",
            enableRowGroup: true,
            enableValue: true,
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.requestControl],
        config: {
            headerName: getLocalizedString("amountOfDays"),
            field: "duration_days",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.requestControl],
        config: {
            headerName: getLocalizedString("startDate"),
            field: "start_date",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            enableRowGroup: true,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.requestControl],
        config: {
            headerName: getLocalizedString("endDate"),
            field: "end_date",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            enableRowGroup: true,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.requestControl],
        config: {
            headerName: getLocalizedString("timePerDay"),
            field: "time_per_day",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.requestControl],
        config: {
            headerName: getLocalizedString("value"),
            field: "value",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.requestControl],
        config: {
            headerName: getLocalizedString("comments"),
            field: "comments",
            filter: "agTextColumnFilter",
        }
    },
]