import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getSchedulesProgrammingAPI,
  createSchedulesProgrammingAPI,
  updateSchedulesProgrammingAPI,
  deleteSchedulesProgrammingAPI,
} from "@icarius-connection/api";
import { getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      errorString = 'Ya existe un horario programado para el turno con esa fecha';
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getSchedulesProgrammingAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_ROWS });
  try {
    let response = await getSchedulesProgrammingAPI();

    let rows = response.data && response.data.data;
    let turnList = response.data && response.data.turnList;
    let schedulesList = response.data && response.data.schedulesList;

    dispatch({
      type: actionTypes.GET_ROWS_FULFILLED,
      payload: { rows, turnList, schedulesList }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_ROWS_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createSchedulesProgrammingAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createSchedulesProgrammingAPI(data);
    let rows = response.data && response.data.data;

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { rows }
    });
    dispatch(openSnackbarAction({ msg: "Horario programado con éxito", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const updateSchedulesProgrammingAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await updateSchedulesProgrammingAPI(data);
    let rows = response.data && response.data.data;

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { rows }
    });
    dispatch(openSnackbarAction({ msg: "Horario programado con éxito", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteSchedulesProgrammingAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteSchedulesProgrammingAPI(data);
    let rows = response.data && response.data.data;

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { rows }
    });
    dispatch(openSnackbarAction({ msg: "Horario programado con éxito", severity: "success" }));
    return response;
  } catch (e) {
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    errorHandler(e, dispatch);
  }
};