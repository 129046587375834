import paths from "@icarius-localization/paths";

export default [
    {
        pages: [paths.relatedTablesDefinition],
        config: {
            headerName: 'Tabla origen',
            field: "fromTable",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.relatedTablesDefinition],
        config: {
            headerName: 'Campo origen',
            field: "fromField",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.relatedTablesDefinition],
        config: {
            headerName: 'Nombre del campo origen',
            field: "fromFieldName",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.relatedTablesDefinition],
        config: {
            headerName: 'Tabla destino',
            field: "toTable",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.relatedTablesDefinition],
        config: {
            headerName: 'Campo código destino',
            field: "toField",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.relatedTablesDefinition],
        config: {
            headerName: 'Campo descripción destino',
            field: "toFieldDescription",
            filter: "agSetColumnFilter",
        }
    },
]