import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { previewFromURL } from "@icarius-utils/download";
import {
  getDocumentsRequests,
  signDocuments,
  previewDocumentsAPI,
  deleteDocumentsApi,
} from "@icarius-connection/api";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import { RESOURCES_ENDPOINT } from "@icarius-connection/endpoints";

export const getDocumentsRequestsAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_REQUESTED_DOCUMENTS_ROWS });
  try {
    let response = await getDocumentsRequests();
    const docsRequested = response.data && response.data.docsRequested;
    const approvants = response.data && response.data.approvants;
    const dateFormat = response.data && response.data.dateFormat;

    if (response.data.status !== "OK") {
      dispatch({ type: actionTypes.GET_REQUESTED_DOCUMENTS_ROWS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("noRequestedDocuments"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_REQUESTED_DOCUMENTS_ROWS_FULFILLED,
      payload: {
        requestedDocuments: docsRequested,
        dateFormat: dateFormat,
        approvants: approvants,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REQUESTED_DOCUMENTS_ROWS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const signDocumentsAction = (docs, pin) => async (dispatch) => {
  dispatch({ type: actionTypes.SIGN_DOCUMENTS });
  try {
    dispatch(openSnackbarAction({ msg: getLocalizedString("settingDocuments"), severity: "info", duration: null }));

    let response = await signDocuments({ docs: docs, password: pin });

    let title = response.headers["x-content-namefile"];

    if (title === "TEMPLATE_NOT_FOUND") {
      dispatch({ type: actionTypes.SIGN_DOCUMENTS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    if (response.status !== 200) {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      dispatch({ type: actionTypes.SIGN_DOCUMENTS_REJECTED });
      return;
    }

    dispatch({
      type: actionTypes.SIGN_DOCUMENTS_FULFILLED,
      payload: {
        documents: docs,
      },
    });

    return response;
  } catch (e) {
    if (e?.response?.data?.status === "VALIDATION_ERROR" && e.response?.data?.rejectedValues[0].error === "TEMPLATE_NOT_FOUND") {
      dispatch(openSnackbarAction({ msg: getLocalizedString("documentInvalidTemplate"), severity: "error" }));
    } else {
      if (e.response && e.response.status && e.response.status === 401) {
        dispatch(openSnackbarAction({ msg: getLocalizedErrorString("incorrectPin"), severity: "error" }));
      } else {
        dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      }
    }
    dispatch({ type: actionTypes.SIGN_DOCUMENTS_REJECTED });
    return;
  }
};

export const previewDocumentsAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.DOWNLOAD_MULTIPLE_DOCUMENTS });
  try {
    let response = await previewDocumentsAPI({ docs: data });
    let title = response.headers["x-content-namefile"];

    if (title === "TEMPLATE_NOT_FOUND") {
      dispatch({ type: actionTypes.DOWNLOAD_MULTIPLE_DOCUMENTS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    if (response.status !== 200) {
      dispatch({ type: actionTypes.DOWNLOAD_MULTIPLE_DOCUMENTS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DOWNLOAD_MULTIPLE_DOCUMENTS_FULFILLED,
    });

    previewFromURL(RESOURCES_ENDPOINT + response.data.path);

    return response;
  } catch (e) {
    if (e?.response?.data?.status === "VALIDATION_ERROR" && e?.response?.data?.error === "TEMPLATE_NOT_FOUND") {
      dispatch(openSnackbarAction({ msg: getLocalizedString("documentInvalidTemplate"), severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }

    dispatch({ type: actionTypes.DOWNLOAD_MULTIPLE_DOCUMENTS_REJECTED, payload: e });
  }
};

export const deleteDocumentsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_DOCUMENTS });
  try {
    let response = await deleteDocumentsApi(dataToSend);

    if (response.status !== 200) {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      dispatch({ type: actionTypes.DELETE_DOCUMENTS_REJECTED });
      return;
    }

    dispatch({
      type: actionTypes.DELETE_DOCUMENTS_FULFILLED,
    });

    return response;
  } catch (e) {
    dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
    dispatch({ type: actionTypes.DELETE_DOCUMENTS_REJECTED });
    return;
  }
};