import paths from "@icarius-localization/paths";

export default [
    {
        pages: [paths.schedulesDefinition],
        config: {
            headerName: 'Código del horario',
            field: "code",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.schedulesDefinition],
        config: {
            headerName: 'Nombre del horario',
            field: "name",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.schedulesDefinition],
        config: {
            headerName: 'Grupo del horario',
            field: "group",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.schedulesDefinition],
        config: {
            headerName: 'Horario programado',
            field: "isProgrammableString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.schedulesDefinition],
        config: {
            headerName: 'Entrada programada',
            field: "entryProg",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.schedulesDefinition],
        config: {
            headerName: 'Salida programada',
            field: "exitProg",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.schedulesDefinition],
        config: {
            headerName: 'Horas totales del turno',
            field: "totalHours",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.schedulesDefinition],
        config: {
            headerName: 'Break programado',
            field: "breakProgString",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.schedulesDefinition],
        config: {
            headerName: 'Inicio break programado',
            field: "breakStart",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.schedulesDefinition],
        config: {
            headerName: 'Término break programado',
            field: "breakEnd",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.schedulesDefinition],
        config: {
            headerName: 'Tiempo del break',
            field: "timeBreak",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.schedulesDefinition],
        config: {
            headerName: 'Horas netas del turno',
            field: "netHours",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.schedulesDefinition],
        config: {
            headerName: 'Tolerancia de atraso en la entrada',
            field: "entryTolerance",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.schedulesDefinition],
        config: {
            headerName: 'Tolerancia antes de horas extras',
            field: "additionalsTolerance",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.schedulesDefinition],
        config: {
            headerName: 'Horario abreviado',
            field: "abrev",
            filter: "agTextColumnFilter",
        }
    },
]