import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDocumentsRequestsAction,
  signDocumentsAction,
  previewDocumentsAction,
  deleteDocumentsAction,
} from "../actions";
import {
  getRequestedDocuments,
  getIsLoading,
  getDateFormat,
  getLocale,
  getApprovants,
} from "../selectors";
import { getIsExportingGrid } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import { getProgressIsOpen } from "@icarius-common/circularProgress/selectors";
import paths from "@icarius-localization/paths";
import ConfirmPinDialog from "@icarius-common/confirmPinDialog";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { VisibilityIcon } from "@icarius-icons";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { openDialogAction } from "@icarius-common/dialog/actions";

const MODES = {
  sign: 'sign',
  delete: 'delete',
  none: '',
}

const RequestedDocuments = () => {

  const [mode, setMode] = useState(MODES.none);
  const [confirmPinDialogOpen, setConfirmPinDialogOpen] = useState(false);
  const [documents, setDocuments] = useState([]);

  const dispatch = useDispatch();

  const requestedDocuments = useSelector(getRequestedDocuments);
  const approvants = useSelector(getApprovants);
  const loadingStatus = useSelector(getIsLoading);
  const exportingGridStatus = useSelector(getIsExportingGrid);
  const dateFormat = useSelector(getDateFormat);
  const locale = useSelector(getLocale);
  const isProgressLoading = useSelector(getProgressIsOpen);

  useEffect(() => {
    dispatch(getDocumentsRequestsAction());
  }, [dispatch])

  const handleDeleteDocuments = (gridRef) => {
    if (gridRef.api.getSelectedRows().length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    let documents = gridRef.api.getSelectedRows().map(item => item.dataSign);

    if (documents.length < 1) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("signUnauthDocuments"), severity: "warning" }));
      return;
    }

    setDocuments(documents);
    setConfirmPinDialogOpen(true);
    setMode(MODES.delete);
  };

  const handleSignDocuments = (gridRef) => {
    if (gridRef.api.getSelectedRows().length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    let documents = gridRef.api.getSelectedRows().filter(item => item.signableForSigner).map(item => item.dataSign);

    if (documents.length < 1) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("signUnauthDocuments"), severity: "warning" }));
      return;
    }

    if (!documents.every(item => item.multipleApprovation === documents[0].multipleApprovation)) {
      dispatch(openDialogAction({
        title: 'Atención',
        msg: <>
          La selección de documentos realizada incluye aprobaciones simples y multiples.
          <br />
          Para firmar la documentación, debe hacerlo separando los grupos de documentos teniendo en cuenta el tipo de aprobación de los mismos.
        </>,
        acceptOnly: true,
      }));
      return;
    }

    setDocuments(documents);
    setConfirmPinDialogOpen(true);
    setMode(MODES.sign);
  };

  const handlePreviewDocuments = (gridRef) => {
    if (gridRef.api.getSelectedRows().length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    let documents = gridRef.api.getSelectedRows().map(item => {
      return {
        "code": item.dataSign.code,
        "codRef": item.dataSign.codRef,
        "dataCode": item.dataSign.dataCode,
        "role": item.dataSign.destRole
      }
    });
    dispatch(previewDocumentsAction(documents));
  };

  const handleSign = (pin, nextSigner) => {
    if (documents.length < 1) return;

    let dataToSend;

    if (nextSigner) {
      // agrego el firmante al array
      dataToSend = documents.map(item => {
        return (
          {
            ...item,
            approvants: [...item.approvants, nextSigner],
          }
        )
      })
    } else {
      dataToSend = documents;
    }

    dispatch(signDocumentsAction(dataToSend, pin))
      .then(() => {
        setDocuments([]);
        setMode(MODES.none);
      })
  };

  const handleDelete = (pin) => {
    if (documents.length < 1) return;

    const dataToSend = {
      password: pin,
      docs: documents.map(doc => {
        return (
          {
            tipoDoc: doc.tipoDoc,
            codRef: doc.codRef,
            dataCode: doc.dataCode,
            destRole: doc.destRole,
          }
        )
      })
    }

    dispatch(deleteDocumentsAction(dataToSend))
      .then(() => {
        setDocuments([]);
        setMode(MODES.none);
        dispatch(getDocumentsRequestsAction());
      })
  };

  const previewDocumentsButton = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("previewDocumentsTooltip")}
      onClick={() => handlePreviewDocuments(gridRef)}
    >
      <VisibilityIcon />
    </CustomIconButton>

  const signButton = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("signTooltip")}
      onClick={() => handleSignDocuments(gridRef)}
      type={"download"}
    />

  const deleteButton = (gridRef) =>
    <CustomIconButton
      title={'Eliminar documentos'}
      onClick={() => handleDeleteDocuments(gridRef)}
      type={"delete"}
    />

  const getSignCallback = () => {
    if (mode === MODES.sign) {
      return handleSign;
    }
    return handleDelete;
  }

  const getSignText = () => {
    if (mode === MODES.sign) {
      return getLocalizedString("confirmAcceptRequestedDocuments");
    }
    return '¿Desea eliminar los documentos seleccionados?';
  }

  return (
    <>
      <CommonPage
        menuItems={[previewDocumentsButton, signButton, deleteButton]}
        rowData={requestedDocuments}
        title={getLocalizedString("requestedDocumentsTitle")}
        gridTitle={getLocalizedString("requestedDocumentsTitle")}
        locale={locale}
        dateFormat={dateFormat}
        columnDefPage={paths.requestedDocuments}
        isLoading={loadingStatus || exportingGridStatus}
        hideLoading={isProgressLoading}
        hasExpand
        hasHelp
      />
      {
        confirmPinDialogOpen &&
        <ConfirmPinDialog
          open={confirmPinDialogOpen}
          text={getSignText()}
          requiresSelection={mode !== MODES.delete && documents[0]?.multipleApprovation}
          selectionData={approvants}
          handleConfirm={getSignCallback()}
          handleClose={() => setConfirmPinDialogOpen(false)}
        />
      }
    </>
  );
}

export default RequestedDocuments;
