import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoading,
  getData,
} from "../selectors";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import {
  deleteRelatedTableDefinitionAction,
  getRelatedTablesDefinitionAction,
} from "../actions";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getLocalizedString } from "@icarius-localization/strings";

const useRelatedTablesDefinition = () => {

  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null); //seleccionado para editar

  const dispatch = useDispatch();
  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);

  useEffect(() => {
    dispatch(getRelatedTablesDefinitionAction());
  }, [dispatch])

  const handleOpenCreateDialog = () => {
    setSelectedDocument(null);
    setCreateDialogIsOpen(true);
  }

  const handleDelete = (document) => {
    dispatch(deleteRelatedTableDefinitionAction({ internalCode: document.internalCode }));
  }

  const handleOpenDeleteDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    if (documents[0].status === 'H') {
      dispatch(openSnackbarAction({ msg: 'No es posible eliminar el calendario porque se encuentra en estado histórico', severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: '¿Esta seguro que desea eliminar el registro?',
      onConfirm: () => handleDelete(documents[0]),
    }));
  }

  const handleOpenEditDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    if (documents[0].status === 'H') {
      dispatch(openSnackbarAction({ msg: 'No es posible editar el calendario porque se encuentra en estado histórico', severity: "warning" }));
      return;
    }

    setSelectedDocument(documents[0]);
    setEditDialogIsOpen(true);
  }

  const handleCloseCreateEditDialog = () => {
    setCreateDialogIsOpen(false);
    setEditDialogIsOpen(false);
    setSelectedDocument(null);
  }


  const state = {
    editDialogIsOpen,
    createDialogIsOpen,
    selectedDocument,
  }

  const handlers = {
    handleOpenDeleteDialog,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseCreateEditDialog,
  }

  return {
    data,
    isLoading,
    state,
    handlers
  }
}

export default useRelatedTablesDefinition;