import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import DateFnsUtils from "@date-io/date-fns";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import DialogTransition from "@icarius-common/dialogTransition";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { useDispatch } from "react-redux";
import { modifyRecognitionPlansAssignmentAPIAction } from "../../actions";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";

const gridStyle = { height: 75, padding: "0px 10px" };

const EditDialog = (props) => {

  const {
    open,
    loading,
    planCode,
    data,
    handleClose,
    planName,
  } = props;

  const dispatch = useDispatch();

  const modify = (dataToSend) => {
    dispatch(modifyRecognitionPlansAssignmentAPIAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    formData,
    setFormValue,
    submit,
  } = useHandleForm(data, planCode, planName, modify, openValidationError);

  if (loading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {`Modificar asignación al plan`}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent style={{ paddingTop: 0 }}>
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                disabled
                fullWidth
                margin={"none"}
                label={"Nombre del plan"}
                value={formData["Nombre del plan"]}
                style={{ marginTop: 10 }}
                inputProps={{ maxLength: 100 }}
                type={"text"}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                disabled
                fullWidth
                margin={"none"}
                label={"Código de empleado"}
                value={formData["Código de empleado"]}
                style={{ marginTop: 10 }}
                inputProps={{ maxLength: 100 }}
                type={"text"}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                disabled
                fullWidth
                margin={"none"}
                label={"Apellido y nombres"}
                value={formData["Apellido y nombres"]}
                style={{ marginTop: 10 }}
                inputProps={{ maxLength: 100 }}
                type={"text"}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  disabled
                  fullWidth
                  label={"Fecha de asignación al plan"}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  invalidDateMessage=''
                  format="dd/MM/yyyy"
                  margin="normal"
                  value={formData["Fecha de asignación al plan"]}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <FormControlLabel
                control={
                  <Switch
                    checked={Boolean(formData["Estado"])}
                    onChange={(e) => setFormValue(!formData['Estado'], "Estado")}
                    value={formData["Estado"]}
                    color="primary"
                  />
                }
                label={"Activo"}
                labelPlacement="start"
                style={{ margin: 5, marginLeft: -55 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
          <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default EditDialog;
