import NAME from "./constants";

export const getPeopleRows = store => store[NAME].peopleRows || [];
export const getHelp = store => store[NAME].helpDocumentation;
export const gettingPeopleRows = store => store[NAME].gettingPeopleRows;
export const getLocale = store => store[NAME].locale;
export const getDateFormat = store => store[NAME].dateFormat;
export const gettingEmployee = store => store[NAME].gettingEmployee;
export const getReceipts = store => store[NAME].receipts;
export const getCalculationProcesses = store => store[NAME].calculationProcesses;

export const getIsLoading = store =>
  store[NAME].gettingPeopleRows ||
  gettingEmployee(store) ||
  store[NAME].gettingReceipt ||
  store[NAME].gettingColaboratorsForReceipt ||
  store[NAME].loadingCanSign;

