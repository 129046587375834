import React from "react";
import { useDispatch } from "react-redux";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  Typography,
  InputLabel,
  Stepper,
  Step,
  StepButton,
  Radio,
  Slider,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { createRecognitionPlanAction, updateRecognitionPlanAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import useHandleStepper from "@icarius-utils/hooks/useHandleStepper";
import MedalAndShieldContainer from "./medalAndShieldContainer";
import NumberFormatCustom from '@icarius-pages/myRequestsManager/components/modalRequests/numberFormatCustom';

const gridStyle = { height: 75, padding: "0px 10px" };

const StepContainer = ({ title, children }) => {
  return (
    <Grid container item xs={12} justify="center">
      <Grid container item sm={12} md={10}>
        <Typography className="whiteText" style={{ fontSize: 20, width: '100%', padding: '0px 10px' }}>
          {title}
        </Typography>
        <Grid container item alignItems="center" justify="space-between" xs={12} style={{ paddingTop: 10 }}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  )
}

const Form = (props) => {

  const {
    data,
    statusList,
    changePointsList,
    priceAwardingList,
    diffusionList,
    annotationList,
    handleClose,
  } = props;
  const dispatch = useDispatch();

  const create = (dataToSend) => {
    dispatch(createRecognitionPlanAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(updateRecognitionPlanAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit,
    stepOneIsValid,
    stepTwoIsValid,
    stepThreeIsValid,
    stepFourIsValid,
  } = useHandleForm(data, create, modify, openValidationError);

  const steps = [
    {
      name: 'Identificación del plan',
      render: () => {
        return (
          <StepContainer title={'Indique los parámetros del nuevo plan de reconocimiento'}>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                disabled={!isCreate}
                required
                fullWidth
                margin={"none"}
                label={'Código'}
                value={formData.code}
                inputProps={{ maxLength: 20 }}
                onChange={(e) => setFormValue(e.target.value, "code")}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                required
                fullWidth
                margin={"none"}
                label={'Nombre'}
                value={formData.name}
                inputProps={{ maxLength: 100 }}
                onChange={(e) => setFormValue(e.target.value, "name")}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                required
                label={'Valor de cada punto'}
                value={formData.pointValue}
                fullWidth
                margin={"none"}
                onChange={(e) => setFormValue(e.target.value, 'pointValue')}
                InputProps={{ inputComponent: NumberFormatCustom, min: "0", step: "0.01" }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                label={'Presupuesto máximo del plan en dinero'}
                value={formData.maxBudget}
                fullWidth
                margin={"none"}
                onChange={(e) => setFormValue(e.target.value, 'maxBudget')}
                InputProps={{ inputComponent: NumberFormatCustom, min: "0", step: "0.01" }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                required
                label={'Peso específico'}
                value={formData.specificWeight}
                fullWidth
                margin={"none"}
                type="number"
                onChange={(e) => setFormValue(e.target.value, 'specificWeight')}
                inputProps={{ min: "1", step: "0.01" }}
                onBlur={e => {
                  if (e.target.value !== "") {
                    e.target.value = Number.parseFloat(e.target.value).toFixed(2);
                    setFormValue(e.target.value, 'specificWeight');
                  }
                }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id={'label-status'}>{'Estado del plan'}</InputLabel>
                <Select
                  value={formData.status}
                  labelId={'label-status'}
                  id={'select-status'}
                  onChange={(e) => setFormValue(e.target.value, "status")}
                  margin={"none"}
                >
                  {
                    statusList.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </StepContainer>
        )
      }
    },
    {
      name: 'Puntos y Esquema de canje',
      render: () => {
        const fields = [
          ["toMedalBronzePoints", "changePointsMedalBronze"],
          ["toMedalSilverPoints", "changePointsMedalSilver"],
          ["toMedalGoldPoints", "changePointsMedalGold"],
          ["toShieldBronzePoints", "changePointsShieldBronze"],
          ["toShieldSilverPoints", "changePointsShieldSilver"],
          ["toShieldGoldPoints", "changePointsShieldGold"],
        ];

        const slots = fields.map(([field1, field2], index) => {
          return (
            <div key={index}>
              <Grid container item alignItems="center" xs={12} style={gridStyle}>
                <TextField
                  label={'Puntos para obtenerlo'}
                  value={formData[field1]}
                  fullWidth
                  margin={"none"}
                  type="number"
                  onChange={(e) => setFormValue(e.target.value, field1)}
                  inputProps={{ min: "0", step: "1" }}
                  onBlur={e => {
                    if (e.target.value !== "") {
                      e.target.value = Number.parseFloat(e.target.value).toFixed(0);
                      setFormValue(e.target.value, field1);
                    }
                  }}
                />
              </Grid>
              <Grid container item alignItems="center" xs={12} style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id={`label-${field2}`}>{'Canje por puntos'}</InputLabel>
                  <Select
                    value={formData[field2]}
                    labelId={`label-${field2}`}
                    id={`select-${field2}`}
                    onChange={(e) => setFormValue(e.target.value, field2)}
                    margin={"none"}
                  >
                    {
                      changePointsList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            </div>
          )
        });

        return (
          <StepContainer title={'Elija la cantidad de puntos para alcanzar cada reconocimiento, y el tipo de canje'}>
            <MedalAndShieldContainer
              slot1={slots[0]}
              slot2={slots[1]}
              slot3={slots[2]}
              slot4={slots[3]}
              slot5={slots[4]}
              slot6={slots[5]}
            />
          </StepContainer>
        )
      },
    },
    {
      name: 'Canje',
      render: () => {
        const fields = [
          ["obligationChangeMedalBronze", "percentMoneyChangeMedalBronze"],
          ["obligationChangeMedalSilver", "percentMoneyChangeMedalSilver"],
          ["obligationChangeMedalGold", "percentMoneyChangeMedalGold"],
          ["obligationChangeShieldBronze", "percentMoneyChangeShieldBronze"],
          ["obligationChangeShieldSilver", "percentMoneyChangeShieldSilver"],
          ["obligationChangeShieldGold", "percentMoneyChangeShieldGold"],
        ];

        const slots = fields.map(([field1, field2], index) => {
          return (
            <div key={index}>
              <Grid container item alignItems="center" xs={12}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }} onClick={() => setFormValue('Y', field1)}>
                  <Radio color='primary' checked={formData[field1] !== "N"} />
                  <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                    {'Obligatorio'}
                  </Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => setFormValue('N', field1)}>
                  <Radio color='primary' checked={formData[field1] !== "Y"} />
                  <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                    {'Optativo'}
                  </Typography>
                </div>
              </Grid>
              <Grid container item alignItems="center" xs={12} style={{ ...gridStyle, height: '' }}>
                <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                  {'% máximo para canje directo por dinero'}
                </Typography>
                <Slider
                  onChange={(_, value) => setFormValue(value, field2)}
                  min={0}
                  max={100}
                  step={1}
                  defaultValue={0}
                  value={parseInt(formData[field2])}
                />
                <Typography className="whiteText" variant="subtitle1" align="center" style={{ width: '100%' }}>
                  {parseInt(formData[field2])}%
                </Typography>
              </Grid>
            </div>
          )
        });

        return (
          <StepContainer title={'Seleccione el formato del canje'}>
            <MedalAndShieldContainer
              slot1={slots[0]}
              slot2={slots[1]}
              slot3={slots[2]}
              slot4={slots[3]}
              slot5={slots[4]}
              slot6={slots[5]}
            />
          </StepContainer>
        )
      },
    },
    {
      name: 'Premios y Difusión',
      render: () => {
        const fields = [
          ["priceAwardingMedalBronze", "diffusionMedalBronze"],
          ["priceAwardingMedalSilver", "diffusionMedalSilver"],
          ["priceAwardingMedalGold", "diffusionMedalGold"],
          ["priceAwardingShieldBronze", "diffusionShieldBronze"],
          ["priceAwardingShieldSilver", "diffusionShieldSilver"],
          ["priceAwardingShieldGold", "diffusionShieldGold"],
        ];

        const slots = fields.map(([field1, field2], index) => {
          return (
            <div key={index}>
              <Grid container item alignItems="center" xs={12} style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id={`label-${field1}`}>{'Entrega de premio'}</InputLabel>
                  <Select
                    value={formData[field1]}
                    labelId={`label-${field1}`}
                    id={`select-${field1}`}
                    onChange={(e) => setFormValue(e.target.value, field1)}
                    margin={"none"}
                  >
                    {
                      priceAwardingList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item alignItems="center" xs={12} style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id={`label-${field2}`}>{'Difusión'}</InputLabel>
                  <Select
                    value={formData[field2]}
                    labelId={`label-${field2}`}
                    id={`select-${field2}`}
                    onChange={(e) => setFormValue(e.target.value, field2)}
                    margin={"none"}
                  >
                    {
                      diffusionList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            </div>
          )
        });

        return (
          <StepContainer title={'Elija la entrega de premios y tipo de difusión'}>
            <MedalAndShieldContainer
              slot1={slots[0]}
              slot2={slots[1]}
              slot3={slots[2]}
              slot4={slots[3]}
              slot5={slots[4]}
              slot6={slots[5]}
            />
          </StepContainer>
        )
      },
    },
    {
      name: 'Registro en anotaciones',
      render: () => {
        const fields = [
          "annotationRecordMedalBronze",
          "annotationRecordMedalSilver",
          "annotationRecordMedalGold",
          "annotationRecordShieldBronze",
          "annotationRecordShieldSilver",
          "annotationRecordShieldGold",
        ];

        const slots = fields.map((field1, index) => {
          return (
            <div key={index}>
              <Grid container item alignItems="center" xs={12} style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id={`label-${field1}`}>{'Registro de incidentes críticos:'}</InputLabel>
                  <Select
                    value={formData[field1]}
                    labelId={`label-${field1}`}
                    id={`select-${field1}`}
                    onChange={(e) => setFormValue(e.target.value, field1)}
                    margin={"none"}
                  >
                    {
                      annotationList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            </div>
          )
        });

        return (
          <StepContainer title={'Elija como quedará registro en las anotaciones'}>
            <MedalAndShieldContainer
              slot1={slots[0]}
              slot2={slots[1]}
              slot3={slots[2]}
              slot4={slots[3]}
              slot5={slots[4]}
              slot6={slots[5]}
            />
          </StepContainer>
        )
      },
    },
  ];

  const {
    currentStep,
    setCurrentStep,
    setNextStep,
    setPreviousStep,
    renderStep,
  } = useHandleStepper(steps);

  const handleSetNextStep = async () => {
    let isValid = await [stepOneIsValid, stepTwoIsValid, stepThreeIsValid, stepFourIsValid][currentStep]();

    if (!isValid) {
      openValidationError();
      return;
    }

    setNextStep();
  }

  return (
    <>
      <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
        <Grid container item xs={12} justify="center">
          <Stepper activeStep={currentStep} alternativeLabel style={{ width: '100%' }}>
            {
              steps.map((step, index) => {
                return (
                  <Step key={index}>
                    <StepButton onClick={() => index < currentStep && setCurrentStep(index)}>{step.name}</StepButton>
                  </Step>
                );
              })
            }
          </Stepper>
        </Grid>
        <Grid container item xs={12}>
          {renderStep()}
        </Grid>
      </Grid>
      <Grid container item sm={12} justify="center">
        <Grid container item sm={12} md={10} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20, paddingTop: 10, paddingRight: 10 }}>
          {
            (currentStep !== 0) &&
            <div style={{ marginRight: 10 }}>
              <ButtonDialogAction isAccept onClick={setPreviousStep} text={'Anterior'} />
            </div>
          }
          {
            (currentStep === steps.length - 1)
              ? <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
              : <ButtonDialogAction isAccept onClick={handleSetNextStep} text={'Siguiente'} />
          }
        </Grid>
      </Grid>
    </>
  );
}



export default Form;
