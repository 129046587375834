import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import { useHistory } from "react-router-dom";
import { SearchIcon } from "@icarius-icons/";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import TocIcon from '@material-ui/icons/Toc';
import CreateEditForm from "./createEditForm";
import DetailGridDialog from "./dialogs/detailGridDialog";
import ViewStatsGridDialog from "./dialogs/viewStatsGridDialog";
import ViewPlanDialog from "./dialogs/viewPlanDialog";
import GraphsDialog from "./dialogs/graphsDialog";
import CollaboratorsInPlansDialog from "./dialogs/collaboratorsInPlansDialog";
import DuplicateDialog from "./dialogs/duplicateDialog";
import useRecognitionPlans from "./useRecognitionPlans";

const RecognitionPlans = () => {

  const history = useHistory();

  const {
    data,
    statusList,
    changePointsList,
    priceAwardingList,
    diffusionList,
    annotationList,
    isLoading,
    state,
    handlers,
  } = useRecognitionPlans();

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => history.push(paths.recognitionScreenSelector)}
      type={"goBack"}
    />
  )

  const createButton = () => (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={() => handlers.handleOpenCreateDialog()}
      type={"add"}
    />
  )

  const modifyButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />
  )

  const duplicateButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("duplicate")}
      onClick={() => handlers.handleOpenDuplicateDialog(gridRef)}
      type={"duplicate"}
    />
  )

  const deleteButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  const assignButton = (gridRef) => (
    <CustomIconButton
      title={'Asignar colaboradores al plan'}
      onClick={() => handlers.handleOpenAssign(gridRef)}
      type={"addPerson"}
    />
  )

  const detailTableButton = (gridRef) => (
    <CustomIconButton
      title={'Detalle del plan'}
      onClick={() => handlers.handleOpenDetailTable(gridRef)}
    >
      <TocIcon />
    </CustomIconButton>
  )

  const viewPlanDialogButton = (gridRef) => (
    <CustomIconButton
      title={'Visualización del plan'}
      onClick={() => handlers.handleOpenViewPlanDialog(gridRef)}
    >
      <MonetizationOnIcon />
    </CustomIconButton>
  )

  const collaboratorsInPlansDialogButton = (gridRef) => (
    <CustomIconButton
      title={'Colaboradores en planes de reconocimientos'}
      onClick={() => handlers.handleOpenCollaboratorsInPlansDialog(gridRef)}
    >
      <SearchIcon />
    </CustomIconButton>
  )

  const graphicsButton = (gridRef) => data.length > 0 &&
    <CustomIconButton
      title={getLocalizedString("charts")}
      onClick={() => handlers.handleOpenGraphsDialog(gridRef)}
      type={"graphics"}
    />

  return (
    <>
      {
        (state.createDialogIsOpen || state.editDialogIsOpen) &&
        <CreateEditForm
          isLoading={isLoading}
          data={state.selectedData}
          statusList={statusList}
          changePointsList={changePointsList}
          priceAwardingList={priceAwardingList}
          diffusionList={diffusionList}
          annotationList={annotationList}
          handleClose={handlers.handleCloseCreateEditDialog}
        />
      }
      {
        !(state.createDialogIsOpen || state.editDialogIsOpen) &&
        <CommonPage
          isLoading={isLoading}
          title={'Planes de reconocimiento'}
          gridTitle={'Planes de reconocimiento'}
          columnDefPage={paths.recognitionPlans}
          rowData={data}
          menuItems={[
            goBackButton,
            createButton,
            modifyButton,
            deleteButton,
            duplicateButton,
            viewPlanDialogButton,
            graphicsButton,
            detailTableButton,
            collaboratorsInPlansDialogButton,
            assignButton,
          ]}
          hasExpand
          hasHelp
        >
          {
            state.detailDialogIsOpen &&
            <DetailGridDialog
              open={state.detailDialogIsOpen}
              data={state.gridTableData}
              name={state.selectedData.name}
              handleClose={handlers.handleCloseDetailTable}
            />
          }
          {
            state.viewStatsDialogIsOpen &&
            <ViewStatsGridDialog
              open={state.viewStatsDialogIsOpen}
              data={state.statsTableData}
              handleClose={handlers.handleCloseViewStatsDialog}
            />
          }
          {
            state.viewPlanDialogIsOpen &&
            <ViewPlanDialog
              open={state.viewPlanDialogIsOpen}
              name={state.selectedData.name}
              data={state.gridTableData}
              handleClose={handlers.handleCloseViewPlanDialog}
            />
          }
          {
            state.collaboratorsInPlansDialogIsOpen &&
            <CollaboratorsInPlansDialog
              open={state.collaboratorsInPlansDialogIsOpen}
              data={state.collaboratorsInPlansData}
              name={"Colaboradores en planes"}
              theme={state.theme.theme}
              color={state.color}
              dateFormat={"dd/mm/yyyy"}
              currencyLocalization={"da"}
              handleClose={handlers.handleCloseCollaboratorsInPlansDialog}
            />
          }
          {
            state.graphsDialogIsOpen &&
            <GraphsDialog
              open={state.graphsDialogIsOpen && data.length > 0}
              handleClose={handlers.handleCloseGraphsDialog}
              data={state.selectedData}
              theme={state.theme}
              color={state.color}
              handleOpenStatsDialog={handlers.handleOpenViewStatsDialog}
            />
          }
          {
            state.duplicateDialogIsOpen &&
            <DuplicateDialog
              open={state.duplicateDialogIsOpen}
              data={state.selectedData}
              isLoading={isLoading}
              handleClose={handlers.handleCloseDuplicateDialog}
            />
          }
        </CommonPage>
      }
    </>
  )
}

export default RecognitionPlans;