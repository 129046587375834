import React from "react";
import { useDispatch } from "react-redux";
import { createSchedulesDefinitionAction, updateSchedulesDefinitionAction } from "../../actions";
import {
  TextField,
  Grid,
  Typography,
  Checkbox,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";

const gridStyle = { height: 75, padding: "0px 10px" };

const Form = (props) => {

  const { data, handleClose } = props;
  const dispatch = useDispatch();

  const create = (formData) => {
    dispatch(createSchedulesDefinitionAction(formData))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (formData) => {
    dispatch(updateSchedulesDefinitionAction(formData))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, create, modify, openValidationError);

  return (
    <Grid container item xs={12} direction="row" style={{ width: "95%", margin: "auto" }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* code */}
          <TextField
            disabled={!isCreate}
            required
            fullWidth
            margin={"none"}
            label={"Código del horario"}
            value={formData.code}
            onChange={(e) => setFormValue(e.target.value, "code")}
            inputProps={{ maxLength: 12 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* Nombre */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={"Nombre del horario"}
            value={formData.name}
            onChange={(e) => setFormValue(e.target.value, "name")}
            inputProps={{ maxLength: 60 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* grupo */}
          <TextField
            fullWidth
            margin={"none"}
            label={"Grupo del horario"}
            value={formData.group}
            onChange={(e) => setFormValue(e.target.value, "group")}
            inputProps={{ maxLength: 12 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* horario programado */}
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer", paddingRight: 20 }}
            onClick={() => setFormValue(!Boolean(formData.isProgrammable), "isProgrammable")}
          >
            <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
              {'Horario programado'}
            </Typography>
            <Checkbox checked={formData.isProgrammable} />
          </div>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* Entrada programada*/}
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <TimePicker
              required={formData.isProgrammable}
              fullWidth
              clearable
              ampm={false}
              label="Entrada programada"
              value={formData.entryProg}
              onChange={(e) => setFormValue(e, 'entryProg')}
              clearLabel='Limpiar'
              cancelLabel='Cancelar'
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* Salida programada*/}
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <TimePicker
              required={formData.isProgrammable}
              fullWidth
              clearable
              ampm={false}
              label="Salida programada"
              value={formData.exitProg}
              onChange={(e) => setFormValue(e, 'exitProg')}
              clearLabel='Limpiar'
              cancelLabel='Cancelar'
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* Horas totales del turno */}
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <TimePicker
              disabled={formData.isProgrammable}
              required
              fullWidth
              clearable
              ampm={false}
              label="Horas totales del turno"
              value={formData.totalHours}
              onChange={(e) => setFormValue(e, 'totalHours')}
              clearLabel='Limpiar'
              cancelLabel='Cancelar'
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* Break programado*/}
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer", paddingRight: 20 }}
            onClick={() => setFormValue(!Boolean(formData.breakProg), "breakProg")}
          >
            <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
              {'Break programado'}
            </Typography>
            <Checkbox checked={formData.breakProg} />
          </div>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* Inicio break programado */}
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <TimePicker
              required={formData.breakProg}
              fullWidth
              clearable
              ampm={false}
              label="Inicio break programado"
              value={formData.breakStart}
              onChange={(e) => setFormValue(e, 'breakStart')}
              clearLabel='Limpiar'
              cancelLabel='Cancelar'
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* Término break programado */}
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <TimePicker
              required={formData.breakProg}
              fullWidth
              clearable
              ampm={false}
              label="Término break programado"
              value={formData.breakEnd}
              onChange={(e) => setFormValue(e, 'breakEnd')}
              clearLabel='Limpiar'
              cancelLabel='Cancelar'
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* Tiempo del break */}
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <TimePicker
              disabled={formData.breakProg}
              required
              fullWidth
              clearable
              ampm={false}
              label="Tiempo del break"
              value={formData.timeBreak}
              onChange={(e) => setFormValue(e, 'timeBreak')}
              clearLabel='Limpiar'
              cancelLabel='Cancelar'
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* Horas netas del turno */}
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <TimePicker
              disabled
              fullWidth
              clearable
              ampm={false}
              label="Horas netas del turno"
              value={formData.netHours}
              onChange={(e) => setFormValue(e, 'netHours')}
              clearLabel='Limpiar'
              cancelLabel='Cancelar'
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* Tolerancia de atraso en la entrada */}
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <TimePicker
              fullWidth
              clearable
              ampm={false}
              label="Tolerancia de atraso en la entrada"
              value={formData.entryTolerance}
              onChange={(e) => setFormValue(e, 'entryTolerance')}
              clearLabel='Limpiar'
              cancelLabel='Cancelar'
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* Tolerancia antes de horas extras */}
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <TimePicker
              fullWidth
              clearable
              ampm={false}
              label="Tolerancia antes de horas extras"
              value={formData.additionalsTolerance}
              onChange={(e) => setFormValue(e, 'additionalsTolerance')}
              clearLabel='Limpiar'
              cancelLabel='Cancelar'
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* Horario abreviado */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={"Horario abreviado"}
            value={formData.abrev}
            onChange={(e) => setFormValue(e.target.value, "abrev")}
            inputProps={{ maxLength: 16 }}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>
    </Grid>
  );
}

export default Form;
