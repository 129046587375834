import React, { useRef, useState } from "react";
import { decimalStatusBar } from "@icarius-table/utils";
import {
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { CloseIcon } from "@icarius-icons";
import { withStyles } from "@material-ui/core/styles";
import CommonPage from "@icarius-common/commonPage";

const StyledDialog = withStyles({
  paper: {
    minWidth: "90%",
  },
})(Dialog);

const CollaboratorsInPlansDialog = (props) => {

  const {
    currencyLocalization,
    data,
    name,
    dateFormat,
    handleClose,
    open,
  } = props;

  const [includesHistory, setIncludesHistory] = useState(false);
  const ownGridRef = useRef();

  const toggleIncludesHistory = () => {
    // Get a reference to the name filter instance
    let filterInstance = ownGridRef.current.api.getFilterInstance("Estado del plan");

    if (includesHistory === true) {
      // Set the model for the filter
      filterInstance.setModel({
        type: "set",
        values: ["Activo", "Detenido"],
      });
    } else {
      // Set the model for the filter
      filterInstance.setModel(null);
    }

    // Get grid to run filter operation again
    ownGridRef.current.api.onFilterChanged();
    setIncludesHistory(prev => !prev);
  }

  const onFirstDataRendered = (params) => {
    // Get a reference to the name filter instance
    let filterInstance = ownGridRef.current.api.getFilterInstance("Estado del plan");

    // Set the model for the filter
    filterInstance.setModel({
      type: "set",
      values: ["Activo", "Detenido"],
    });

    // Get grid to run filter operation again
    ownGridRef.current.api.onFilterChanged();

    let allColumnIds = [];
    params.columnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    params.columnApi.autoSizeColumns(allColumnIds);
    params.api.addEventListener("rangeSelectionChanged", decimalStatusBar);
    params.api.addEventListener("filterChanged", decimalStatusBar);
    params.api.addEventListener("filterModified", decimalStatusBar);
    params.api.addEventListener("sortChanged", decimalStatusBar);
    params.api.addEventListener("displayedColumnsChanged", decimalStatusBar);
  }

  return (
    <StyledDialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"md"}>
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Colaboradores en planes de reconocimientos"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <CommonPage
            ownGridRef={ownGridRef}
            gridTitle={name}
            columnDefPage={'collaboratorsInPlans'}
            rowData={data}
            dateFormat={dateFormat}
            locale={currencyLocalization}
            gridHeight={"calc(100vh - 220px)"}
            hasExpand
            hasSelectAll
            hasSwitch
            switchValue={Boolean(includesHistory)}
            handleSwitchClick={toggleIncludesHistory}
            switchLabelOn={"Todos los planes"}
            switchLabelOff={"Planes activos y detenidos"}
            onFirstDataRendered={onFirstDataRendered}
            ignoreProcessesbyPath
          />
        </DialogContent>
      </div>
    </StyledDialog>
  );
}

export default CollaboratorsInPlansDialog;
