import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSchedulesProgrammingAction, updateSchedulesProgrammingAction } from "../../actions";
import { Grid, FormControl, Select, MenuItem, InputLabel, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { getSchedulesList, getTurnList } from "@icarius-pages/schedulesProgramming/selectors";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import { CloseIcon } from "@icarius-icons";
import DialogTransition from "@icarius-common/dialogTransition";
import Loader from "@icarius-common/loader";

const gridStyle = { height: 75, padding: "0px 10px" };

const FormDialog = (props) => {

  const { open, isLoading, data, handleClose } = props;
  const dispatch = useDispatch();

  const turnList = useSelector(getTurnList);
  const schedulesList = useSelector(getSchedulesList);

  const create = (formData) => {
    dispatch(createSchedulesProgrammingAction(formData))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (formData) => {
    dispatch(updateSchedulesProgrammingAction(formData))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, create, modify, openValidationError);

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      fullWidth
      maxWidth={"sm"}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {`${isCreate ? 'Crear' : 'Editar'} programación de horario`}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ marginTop: 0 }}>
        <Loader open={isLoading} />
        <Grid container item xs={12} direction="row">
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-turnCode`}>{"Código del turno"}</InputLabel>
                <Select
                  disabled={!isCreate}
                  value={formData.turnCode}
                  labelId={`label-turnCode`}
                  id={`select-turnCode`}
                  onChange={(e) => setFormValue(e.target.value, "turnCode")}
                  margin={"none"}
                >
                  {
                    turnList.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  fullWidth
                  required
                  label={'Fecha de ejecucion específica'}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  invalidDateMessage=''
                  format="dd/MM/yyyy"
                  margin="none"
                  value={formData.scheduleDate}
                  onChange={(e) => setFormValue(e, "scheduleDate")}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-scheduleCode`}>{"Código de horario para la fecha"}</InputLabel>
                <Select
                  value={formData.scheduleCode}
                  labelId={`label-scheduleCode`}
                  id={`select-scheduleCode`}
                  onChange={(e) => setFormValue(e.target.value, "scheduleCode")}
                  margin={"none"}
                >
                  {
                    schedulesList.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </DialogActions>
    </Dialog>
  )
}
export default FormDialog;
