import NAME from "./constants";

export const GET_EMPLOYEE = NAME + "/GET_EMPLOYEE";
export const GET_EMPLOYEE_FULFILLED = NAME + "/GET_EMPLOYEE_FULFILLED";
export const GET_EMPLOYEE_REJECTED = NAME + "/GET_EMPLOYEE_REJECTED";

export const LIKE_REQUEST = NAME + "/LIKE_REQUEST";
export const LIKE_REQUEST_END = NAME + "/LIKE_REQUEST_END";

export const GET_PEOPLE_ROWS = NAME + "/GET_PEOPLE_ROWS";
export const GET_PEOPLE_ROWS_FULFILLED = NAME + "/GET_PEOPLE_ROWS_FULFILLED";
export const GET_PEOPLE_ROWS_REJECTED = NAME + "/GET_PEOPLE_ROWS_REJECTED";

export const DOWNLOAD_MULTIPLE_RECEIPT = NAME + "/DOWNLOAD_MULTIPLE_RECEIPT";
export const DOWNLOAD_MULTIPLE_RECEIPT_FULFILLED = NAME + "/DOWNLOAD_MULTIPLE_RECEIPT_FULFILLED";
export const DOWNLOAD_MULTIPLE_RECEIPT_REJECTED = NAME + "/DOWNLOAD_MULTIPLE_RECEIPT_REJECTED";

export const GET_COLABORATORS_FOR_RECEIPT = NAME + "/GET_COLABORATORS_FOR_RECEIPT";
export const GET_COLABORATORS_FOR_RECEIPT_FULFILLED =
  NAME + "/GET_COLABORATORS_FOR_RECEIPT_FULFILLED";
export const GET_COLABORATORS_FOR_RECEIPT_REJECTED =
  NAME + "/GET_COLABORATORS_FOR_RECEIPT_REJECTED";

export const SIGN_AND_DOWNLOAD = NAME + "/SIGN_AND_DOWNLOAD";
export const SIGN_AND_DOWNLOAD_FULFILLED = NAME + "/SIGN_AND_DOWNLOAD_FULFILLED";
export const SIGN_AND_DOWNLOAD_REJECTED = NAME + "/SIGN_AND_DOWNLOAD_REJECTED";

export const RESTORE_DEFAULT_STATE = NAME + "/RESTORE_DEFAULT_STATE";