import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      'fromTable',
      'fromField',
      'toTable',
      'toField',
      'toFieldDescription',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      'fromTable': yup.string().required(),
      'fromField': yup.string().required(),
      'toTable': yup.string().required(),
      'toField': yup.string().required(),
      'toFieldDescription': yup.string().required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      let dataToSend = {
        ...formData,
      };

      isCreate ? createCallback(dataToSend) : modifyCallback({ ...dataToSend, internalCode: data.internalCode });
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
